import { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { loadingRequest, loadingSuccess } from './actions';

export default function useFetching() {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      fetchLoading: () => dispatch(loadingRequest()),
      fetchSuccess: () => dispatch(loadingSuccess()),
    }),
    [dispatch],
  );
}
