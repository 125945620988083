import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'store/auth';
import { LANG_DE } from 'configs/constants';

interface Props {
  render: (props: any) => any;
  location?: any;
  path: string;
  exact?: boolean;
}

const PrivateRouteUser: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const auth = useAuth();
  const { group_name: groupName, status_name: pending } = auth;
  const { render, location } = props;

  useEffect(() => {
    i18n.changeLanguage(LANG_DE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myRender = () => {
    if (!groupName)
      return () => (
        <Redirect
          to={{
            pathname: '/internal/sign-in',
            state: { from: location },
          }}
        />
      );
    if (pending?.toLowerCase?.().includes('pending'))
      return () => (
        <Redirect
          to={{
            pathname: '/init-profile',
            state: { from: location },
          }}
        />
      );

    if (groupName.includes('admin'))
      return () => (
        <Redirect
          to={{
            pathname: '/404',
            state: { from: location },
          }}
        />
      );
    return render;
  };

  return <Route {...props} render={myRender()} />;
};

export default PrivateRouteUser;
