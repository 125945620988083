export const ISPAID = 'ISPAID';
export const ISUNPAID = 'ISUNPAID';

export const showPopup = (message: string, title: string, type?: boolean, role?: string, isSuccess?: boolean,) => {

    return { type: ISUNPAID, payload: { message, title, type, role, isSuccess } }
}

export const hiddenPopup = () => {
    return { type: ISPAID }
}