export const PAID = 'PAID';
export const UNPAID = 'UNPAID';

export const showPopupPayment = (message: string, payment?: boolean) => {

    return { type: UNPAID, payload: { message, payment } }
}

export const hiddenPopupPayment = () => {
    return { type: PAID }
}