import { includes } from 'lodash';
import {
  FETCH_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  ADD_NOTIFICATION,
  LOADING_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  RESET_NOTIFICATIONS,
  READ_NOTIFICATIONS,
} from './actions';

const initialState = { data: [], loading: false, full: false, page: 0, new: 0 };

export const notificationsReducer = (state = initialState, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  let newData: any[];

  switch (type) {
    case FETCH_NOTIFICATIONS:
      const { data, page, count } = payload;
      const filteredDuplicates = data.filter((item: any) => !includes(state.data, item));
      newData = [...state.data, ...filteredDuplicates];
      const full = newData.length >= count;

      return {
        ...state,
        // Because api is mock data => temporary use random id
        // data: [...state.data, ...data.map((item: any) => ({ ...item, id: Math.random().toString() }))],
        data: newData,
        loading: false,
        full,
        page,
        new: state.new || count || 0,
      };

    case CLEAR_NOTIFICATIONS:
      return { ...initialState, full: true };

    case RESET_NOTIFICATIONS:
      return initialState;

    case ADD_NOTIFICATION:
      // Dont add item with duplicate id
      if (state.data.findIndex((item: any) => item.id === payload.id) !== -1) return state;
      return { ...state, data: [payload, ...state.data], new: state.new + 1 };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        data: state.data.filter((item: any) => item.id !== payload),
        new: state.new > 1 ? state.new - 1 : 0,
      };

    case LOADING_NOTIFICATIONS:
      return { ...state, loading: true };

    case UPDATE_NOTIFICATION:
      newData = [...state.data];
      const { id, newFields } = payload;
      const index = newData.findIndex((item: any) => item.id === id);
      if (index === -1) return state;
      newData[index].body_json = { ...newData[index].body_json, ...newFields };
      return { ...state, data: newData };

    case READ_NOTIFICATIONS:
      return { ...state, new: 0 };

    default:
      return state;
  }
};
