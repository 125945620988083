import { CButton, CModalBody, CModalFooter } from '@coreui/react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createToast } from '../../store/toasts/actions';
import CModal from '../modal/CModal';
import CModalHeader from '../modal/CModalHeader';

interface Props {
  show: boolean;
  handleClose: () => void;
}

const PopupSessionExpired: React.FC<Props> = (props) => {
  const { show, handleClose } = props;

  const location = useLocation();
  const dispatch = useDispatch();
  const isAdmin = location.pathname.includes('/admin');

  useEffect(() => {
    if (!isAdmin && show) {
      dispatch(
        createToast({
          title: 'Information',
          body: 'Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an, um die App weiter zu verwenden.',
        }),
      );

      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (isAdmin)
    return (
      <CModal centered show={show} onClose={handleClose} closeOnBackdrop={false}>
        <CModalHeader>Info</CModalHeader>
        <CModalBody style={{ textAlign: 'center' }}>Your session has expired. Please sign in again!</CModalBody>
        <CModalFooter style={{ textAlign: 'center', justifyContent: 'center' }}>
          <CButton color="info" className="px-4" onClick={handleClose}>
            OK
          </CButton>
        </CModalFooter>
      </CModal>
    );

  return <div />;

  // return (
  //   <div className="petrol">
  //     <HBModalConfirm
  //       show={show}
  //       handleClose={handleClose}
  //       title="Die Info"
  //       content="Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an, um die App weiter zu verwenden."
  //       up="OK"
  //       upCallback={handleClose}
  //     />
  //   </div>
  // );
};

export default PopupSessionExpired;
