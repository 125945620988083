export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';

export const CALL_API_REQUEST = 'CALL_API_REQUEST';

export type InputApiType = {
  method: 'get' | 'post' | 'put' | 'delete';
  api: string;
  body?: any;
  loading?: boolean;
  msg?: string;
  refreshed?: boolean;
  token?: string;
  params?: { [key: string]: any };
  hideToast?: boolean;
};

export type OutputApiType = {
  code: number;
  id: string;
  status: 'success' | 'error' | 'warning';
  text: string;
  text_de: string;
  data: any;
};

export const callApiAction = (payload: InputApiType, callback?: (result: OutputApiType) => void) => {
  return { type: CALL_API_REQUEST, payload, callback };
};
