import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import { CALL_API_REQUEST, ERROR, SUCCESS, callApiAction, InputApiType, OutputApiType } from './actions';
import { loadingRequest, loadingSuccess } from '../loading/actions';
import { createToast } from '../toasts/actions';
import { sessionExpire } from '../session/actions';
import { showPopupPayment } from '../payment/actions';
import config from '../../config';
import { getUserLS, setUserLS } from '../../utils';
import { refreshToken } from '../auth/actions';
// import * as Sentry from '@sentry/browser';
import { DEFAULT_MESSAGE_ERROR, NETWORK_ERROR_CODE } from 'configs/constants';

type Params = { [key: string]: any };

/**
 * Create params for axios
 * @param params
 */
const createParams = (params?: Params) => {
  if (!params) return undefined;

  return { params };
};

/**
 * Create options when call api from token, params
 * @param value
 */
const createOptions = (value: { token?: string; params?: any }) => {
  const { token, params } = value;
  let options = {};
  if (token) options = { ...options, headers: { Authorization: token } };
  if (params) options = { ...options, params };
  return options;
};

const getApi: any = (input: { api: string; token?: string; params?: Params }) => {
  const { api, params, token } = input;
  let configs: any = {};
  if (token) configs = { headers: { Authorization: token } };
  if (params) configs = { ...configs, params };
  return axios.get(api, configs);
};

const postApi: any = (input: { api: string; token?: string; data: any; params?: Params }) => {
  const { api, data, params } = input;
  return axios.post(api, data, createParams(params));
};

const putApi: any = (input: { api: string; data: any; token?: string; params?: Params }) => {
  const { api, data, token, params } = input;
  return axios.put(api, data, createOptions({ token, params }));
};

const deleteApi: any = (input: { api: string; data?: any; params?: Params }) => {
  const { api, data, params } = input;
  return axios.delete(api, { data, params });
};

const mappingApi: any = {
  get: getApi,
  post: postApi,
  put: putApi,
  delete: deleteApi,
};

function* workerCallApi(action: { payload: InputApiType; callback: (result: OutputApiType) => void; type: string }) {
  const { payload, callback } = action;
  let result: any = {
    code: 500,
    id: '0',
    status: ERROR,
    text: '',
    text_de: '',
    data: null,
  };
  let refresh = false;
  const { method, api, body = {}, loading, msg, token, params, hideToast } = payload || {};
  if (loading) yield put(loadingRequest());
  try {
    const { data: responseData } = yield mappingApi[method]({ api, data: body, token, params });
    const { code: responseCode, message, data } = responseData || {};
    const { text = '', status, text_de = '', popup: isShowPopup, duration = 1 } = message || {};

    result = { ...message, code: responseCode, data };
    if (responseCode === 402) {
      yield put(showPopupPayment(text_de, false));
    } else if (!hideToast && isShowPopup) {
      yield put(createToast(text, status, duration * 1000));
    }
  } catch (error: any) {
    const { response: errResponse } = error || {};
    const { status: errStatus } = errResponse || {};

    if (errStatus === 403 || errStatus === 401) {
      if (!api.includes('signout')) {
        const user = getUserLS();
        if (user && !payload.refreshed) {
          const groupName = user.group_name;
          const { data: responseRefreshData } = yield postApi({
            api: groupName.includes('admin') ? config.rest.refreshTokenAdmin() : config.rest.refreshTokenUser(),
            data: { refresh_token: user.refresh_token },
          });
          const { data, message } = responseRefreshData || {};

          if (message.status === SUCCESS) {
            const access_token = data.access_token;
            const id_token = data.id_token;
            const user = { ...getUserLS(), id_token, access_token };
            delete user.exp;
            delete user.iat;
            setUserLS(user);
            yield put(refreshToken({ id_token, access_token }));
            refresh = true;
            yield put(callApiAction({ ...payload, refreshed: true }, action.callback));
          } else {
            console.log('Refresh failure');
            yield put(sessionExpire());
          }
        } else yield put(sessionExpire());
      }
    } else {
      // Sentry.captureException(error);
      result = {
        code: error.status ? 500 : NETWORK_ERROR_CODE,
        id: '0',
        status: ERROR,
        text: msg,
        text_de: msg,
        data: null,
      };
      const msgToast = msg || DEFAULT_MESSAGE_ERROR;

      if (!hideToast) yield put(createToast(msgToast, ERROR));
    }
  }
  if (loading) yield put(loadingSuccess());
  if (!refresh) {
    callback && callback(result);
  }
}

export function* watcherCallApi() {
  yield takeEvery(CALL_API_REQUEST, workerCallApi);
}
