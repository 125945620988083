import {
  SIGN_IN,
  SIGN_OUT,
  REFRESH_TOKEN,
  UPDATE_PROFILE,
  ADD_STRIPE_PAYMENT,
  REMOVE_STRIPE_PAYMENT,
  UPDATE_STRIPE_PAYMENT,
} from './actions';

const initialState: any = {};

export const authReducer = (state = initialState, action: { type: string; payload: any }) => {
  let newData = [];
  switch (action.type) {
    case SIGN_IN:
      return { ...action.payload };

    case SIGN_OUT:
      return initialState;

    case REFRESH_TOKEN:
    case UPDATE_PROFILE:
      return { ...state, ...action.payload };

    case ADD_STRIPE_PAYMENT:
      return { ...state, stripe_payment: [...state.stripe_payment, action.payload] };

    case UPDATE_STRIPE_PAYMENT:
      newData = [...state.stripe_payment];
      const { id, newFields } = action.payload;
      const index = newData.findIndex((item: any) => item.id === id);
      if (index === -1) return state;
      newData[index].stripe_payment = { ...newData[index].stripe_payment, ...newFields };
      return { ...state, stripe_payment: newData };

    case REMOVE_STRIPE_PAYMENT:
      const stripeData = action.payload;
      return { ...state, stripe_payment: state.stripe_payment.filter((item: any) => item.id !== stripeData.id) };

    default:
      return state;
  }
};
