import React from 'react';
import { useSelector } from 'react-redux';
import { loading } from '../../extensions';

const Loading: React.FC = () => {
  const { loadingCount } = useSelector((state: { loading: { loadingCount: number } }) => state.loading);

  return <div className={loadingCount ? 'loading--fade-in' : 'loading--fade-out'}>{loading}</div>;
};

export default Loading;
