import axios from 'axios';
import config from 'config';
import { ls } from 'extensions';
import { updateProfile } from 'store/auth/actions';
import { addUserLS } from 'utils';

/**
 * Generate the device id notification
 * @param email email of user
 * @param dispatch dispatcher
 */
export const initialDevicesBrowser = async (email: string, dispatch: any) => {
  let deviceId = ls.get('device_id');
  if (deviceId) return;
  let pushToken = ls.get('push_token');

  try {
    await axios
      .post(config.rest.initialDevice(), {
        email_address: email,
        phone_number: '',
        push_token: pushToken || '',
        subscribed: '',
        last_activate: '',
        first_session: '',
        device_name: 'ReactJS',
        sessions: '',
        app_version: '',
        country: '',
        ip_address: '',
        sdk_version: '',
        lat: '',
        long: '',
        usage_duration: '',
        language_code: '',
        external_user_id: '',
        segments: '',
        tags: '',
      })
      .then(function (response) {
        if (response.data.message.status === 'success') {
          ls.set('device_id', response.data.data.device_id);
          const extraUserData = { deviceId: response.data.data.device_id };
          addUserLS(extraUserData);
          dispatch(updateProfile(extraUserData));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } catch (e) {}
};

/**
 * Send device id to server
 * @param email email of user
 * @param deviceId device id generated from server
 */
export const updateDevice = async (email: string, deviceId: string) => {
  // const registered = ls.get('registered');
  // if (registered) return;
  const pushToken = ls.get('push_token');

  try {
    await axios
      .put(config.rest.updateDevice(deviceId), {
        email_address: email,
        phone_number: '',
        push_token: pushToken || '',
        subscribed: '',
        last_activate: '',
        first_session: '',
        device_name: 'ReactJS',
        sessions: '',
        app_version: '',
        country: '',
        ip_address: '',
        sdk_version: '',
        lat: '',
        long: '',
        usage_duration: '',
        language_code: '',
        external_user_id: '',
        segments: '',
        tags: '',
      })
      .then(function (response) {
        if (response.data.message.status === 'success') {
          ls.set('registered', true);
        }
      });
  } catch (e) {}
};
