import React from 'react';
import { ls, jwt } from './extensions';
import config from './config';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';
import { useEffect, useState } from 'react';
import { ERROR } from 'store/callApi/actions';
import crypto from 'crypto';

moment.locale('de');

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const MAX_FILENAME_LENGTH = 250;

export const isDev = () => {
  return development;
};

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = MONTH * 12;

export const timestampToDatetime = (timestamp: number, hasAgo?: boolean) => {
  const now = new Date().getTime();
  const date = new Date(timestamp * 1000);
  const different = (now - date.getTime()) / 1000;
  if (different < MINUTE) return 'Ein Paar Sekunden';
  const prefix = hasAgo ? 'vor ' : '';
  if (different < MINUTE * 2) return prefix + '1 Minute';
  if (different < HOUR) return prefix + Math.floor(different / MINUTE) + ' Minuten';
  if (different < HOUR * 2) return prefix + '1 Stunde';
  if (different < DAY) return prefix + Math.floor(different / HOUR) + ' Stunden';
  if (different < DAY * 2) return prefix + '1 Tag';
  if (different < WEEK) return prefix + Math.floor(different / DAY) + ' Tage' + (hasAgo ? 'n' : '');
  if (different < WEEK * 2) return prefix + '1 Woche';
  if (different < MONTH) return prefix + Math.floor(different / WEEK) + ' Wochen';
  if (different < MONTH * 2) return prefix + '1 Monat';
  if (different < YEAR) return prefix + Math.floor(different / MONTH) + ' Monate' + (hasAgo ? 'n' : '');
  if (different < YEAR * 2) return prefix + '1 Jahr';
  return prefix + Math.floor(different / YEAR) + ' Jahre' + (hasAgo ? 'n' : '');
};

export const createDate = (dateString: string) => {
  if (!dateString) return null;
  const dateParts = dateString.split('/');
  return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
};

export const formatDate = (date: Date) => {
  return date ? moment(date).format('DD/MM/YYYY') : undefined;
};

export const includes = (arr: any[], item: any) => {
  // return arr.filter((i: any) => i.id === item.id).length > 0;
  return arr.findIndex((i: any) => i.id === item.id) !== -1;
};

export const getUserLS = () => {
  const data = ls.get('data');
  if (data) {
    try {
      const user = jwt.verify(data, config.app.secretKey);
      return user;
    } catch (error) {}
  }
  return null;
};

export const setUserLS = (user: any) => {
  axios.defaults.headers.common['Authorization'] = user.id_token;
  delete user.exp;
  delete user.iat;
  const dataEncoded = jwt.sign(user, config.app.secretKey, {
    expiresIn: config.app.expiresIn,
  });
  ls.set('data', dataEncoded);
};

export const addUserLS = (user: any) => {
  const newUser = getUserLS();
  user = { ...newUser, ...user };
  delete user.exp;
  delete user.iat;
  const dataEncoded = jwt.sign(user, config.app.secretKey, {
    expiresIn: config.app.expiresIn,
  });
  ls.set('data', dataEncoded);
};

export const clearLS = () => {
  axios.defaults.headers.common['Authorization'] = '';
  ls.remove('data');
};

export const getErrorsFromValidationError = (validationError: any) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

export const validate = (getValidationSchema: any) => {
  return (values: any) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

export const timestampToHour = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return ('0' + date.getHours()).substr(-2) + ':' + ('0' + date.getMinutes()).substr(-2);
};

export const dateToHour = (date: Date) => {
  return ('0' + date.getHours()).substr(-2) + ':' + ('0' + date.getMinutes()).substr(-2);
};

export const timestampToDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  if (date.toDateString() === new Date().toDateString()) return 'Heute';
  const day = '0' + date.getDate();
  const month = '0' + (date.getMonth() + 1);
  const year = date.getFullYear();
  return day.substr(-2) + '.' + month + '.' + year;
};

export const secondsToDuration = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  let ret = '';
  if (h > 0) ret += h + 'h';
  if (m > 0) ret += (ret !== '' ? ' ' : '') + m + 'min';
  if (s > 0) ret += (ret !== '' ? ' ' : '') + s + 's';
  return ret;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeWordsRegex = (string: string) => {
  return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const capitalizeWords = (string: string) => {
  if (!string) return '';
  return string
    .split(' ')
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .reduce((x, y) => x + ' ' + y);
};

const pageSize = 10;

export const addNo = (item: any, index: number, page: number) => {
  return { ...item, no: index + 1 + pageSize * (page - 1) };
};

export const intToString = (num: any) => {
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: 'T' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ];
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].v) {
      break;
    }
  }
  let shortenNumber: any = 0;
  shortenNumber = num / si[i].v;
  let checkNumber = shortenNumber.toString().split('.');
  if (checkNumber[0].length >= 2) {
    return checkNumber[0] + si[i].s;
  } else if (checkNumber[0].length < 2 && Number(checkNumber[1]) < 100) {
    return Math.trunc(shortenNumber) + si[i].s;
  }
  return shortenNumber.toString().replace('.', ',').slice(0, 3) + si[i].s;
};

export default function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? '13px' : '15px');
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return fontSize;
}

/**
 * Convert filename to ascii
 * @param filename filename before encode
 */
export const encodeFilename = (filename: string = '') => {
  return filename
    .normalize('NFC')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/(\W+)/gi, '_')
    .replace(/\s+/g, '_');
};

/**
 * Filter data to avoid duplicate id
 * @param oldData before data
 * @param newData data want to filter duplicate with before data
 */
const filterDuplicates = (oldData: any[], newData: any[]) => {
  return newData.filter((item: any) => oldData.findIndex((item2: any) => item2.id === item.id) === -1);
};

/**
 * If the page is 1, create array, otherwise, join two array
 * @param page
 * @param oldData
 * @param newData
 */
export const concatData = (page: number, oldData: any[], newData: any[]) =>
  page === 1 ? newData : [...oldData, ...filterDuplicates(oldData, newData)];

/**
 * Return page size 24 if page is 1 and 12 other wise
 * @param page current page
 */
export const getPageSize = (page: number) => (page === 1 ? 24 : 12);

/**
 * Return page size 48 if page is 1 and 24 other wise
 * @param page current page
 */
export const getPageSizeX2 = (page: number) => (page === 1 ? 48 : 24);

/**
 * Get data from page 3 with 12 items per page after page 1 with 24 items per page
 * @param page
 */
export const getNextPage = (page: number) => (page === 1 ? 3 : page + 1);

/**
 * Firefox, some browsers not have property overflow overlay
 */
export const styleOverflowDefault = (process.env.NODE_ENV !== 'test' ? CSS.supports('overflow', 'overlay') : false)
  ? 'overlay'
  : 'unset';

/**
 * Add access token to url
 * @param source
 * @param accessToken
 */
export const addKMSToken = (url: string = '', accessToken: string) => {
  if (!url) return '';
  return url;

  // Remove token, not use
  // Not add token if the url is not from cloundfront
  if (!url.includes('cloudfront.net')) return url;
  return `${url}?token=${accessToken}`;
};

/**
 * Create max date for date picker, only above 18 years old
 */
export const getMaxBirthDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

/**
 * Prevent user types space character in email input
 * @param e keyboard event
 */
export const emailKeydownPreventSpace = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === ' ') e.preventDefault();
};

/**
 * Normalize filename to NFC in safari
 * @param str
 */
export const normalizeNFC = (str: string) => {
  return str?.normalize('NFC');
};

/**
 * Normalize all key to NFC in safari
 * @param obj
 */
export const normalizeNFCObject = (obj: { [key: string]: string }) => {
  Object.keys(obj).forEach((item) => (obj[item] = normalizeNFC(obj[item])));
};

export const createTitleVerifyEmail = (status: string) => {
  return status !== ERROR ? 'E-Mail Bestätigung' : 'Information';
};

/**
 * Encode file name and trim filename length if length > 250 characters, keep extension
 * @param fileName filename before trimmed
 * @param ignoreEncode ignore encode file name
 *
 * @return string is new name after trimmed
 */
export const trimFileName = (fileName: string = '', ignoreEncode: boolean = false) => {
  // Normalize NFC on safari => fix bug germany character
  if (ignoreEncode) fileName = normalizeNFC(fileName);
  else fileName = encodeFilename(fileName);
  if (fileName.length > MAX_FILENAME_LENGTH) {
    const indexExtension = fileName.lastIndexOf('.');

    // if extension length > 5 => only take 4 characters
    const ext = indexExtension > -1 ? fileName.substr(indexExtension).substr(0, 5) : '';
    const newName = fileName.substr(0, MAX_FILENAME_LENGTH - ext.length);
    fileName = newName + ext;
  }
  return fileName;
};

// Show 99+ if amount > 99
export const max99 = (amount: number) => {
  return amount > 99 ? '99+' : amount;
};

// Create link to appointment detail
export const appointmentDetailLocation = (appointmentId: string) => `/consulting/view-appointments/${appointmentId}`;

// Gen uuid v4 with 36 character
export const uuid4 = () => {
  const rnd = crypto.randomBytes(18);
  rnd[6] = (rnd[6] & 0x0f) | 0x40;
  rnd[8] = (rnd[8] & 0x3f) | 0x80;
  return rnd.toString('hex');
};

/**
 * Create function to convert milisecond -> hh:mm:ss
 * @param milisecond
 */
export const formatMiliSecond = (milisecond: any) => {
  if (isNaN(milisecond)) {
    return '00:00';
  }

  const date = new Date(milisecond * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes().toString().padStart(2, '0');
  const ss = date.getUTCSeconds().toString().padStart(2, '0');

  if (hh) {
    return `${hh}:${mm}:${ss}`;
  }

  return `${mm}:${ss}`;
};
