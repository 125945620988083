import config from 'config';
import { callApiAction, SUCCESS } from '../callApi/actions';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const LOADING_NOTIFICATIONS = 'LOADING_NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';

/**
 * Get all notifications from server and add to reducer
 * @param dispatch
 * @param page
 */
export const fetchNotifications = (dispatch: any, page: number) => {
  dispatch({ type: LOADING_NOTIFICATIONS });
  // dispatch({ type: FETCH_NOTIFICATIONS, payload: {data: [], page: 1} });
  // dispatch({ type: FETCH_NOTIFICATIONS, payload: { data: [...sampleData], page } });
  dispatch(
    callApiAction(
      {
        method: 'get',
        api: config.rest.notifications(),
        params: {
          page,
          page_size: page === 1 ? 24 : 12,
        },
      },
      (response: any) => {
        const { status, data } = response;
        if (status === SUCCESS) {
          dispatch({
            type: FETCH_NOTIFICATIONS,
            payload: {
              data: data.notifications,
              page,
              count: data.new_notification_number,
            },
          });
        } else {
          dispatch({ type: FETCH_NOTIFICATIONS, payload: { data: [], page, count: 0 } });
        }
      },
    ),
  );
};

/**
 * Remove all notification items from reducer
 * @param dispatch
 */
export const clearNotifications = (dispatch: any) => {
  dispatch(
    callApiAction({ method: 'delete', api: config.rest.notifications(), loading: true }, (response: any) => {
      const { status } = response;
      if (status === SUCCESS) {
        dispatch({ type: CLEAR_NOTIFICATIONS });
      }
    }),
  );
};

/**
 * Add notification item to reducer,
 * @param dispatch
 * @param payload body data, includes id, type, title, body ...
 */
export const addNotification = (dispatch: any, payload: any) => {
  dispatch({ type: ADD_NOTIFICATION, payload });
};

/**
 * Remove an item from reducer
 * @param dispatch
 * @param id id of item
 */
export const removeNotification = (dispatch: any, id: string, hideToast?: boolean) => {
  dispatch({ type: REMOVE_NOTIFICATION, payload: id });
  dispatch(
    callApiAction({ method: 'delete', api: config.rest.notifications(id), hideToast }, (response: any) => {
      const { status } = response;
      if (status === SUCCESS) {
      }
    }),
  );
};

/**
 * Update some fields of item in reducer
 * @param dispatch
 * @param id id of item needs update
 * @param newFields an object with the fields need update ex: { is_saved: true }
 */
export const updateNotification = (dispatch: any, id: string, newFields: any) => {
  dispatch({ type: UPDATE_NOTIFICATION, payload: { id, newFields } });
};

/**
 * Reload when user sign out
 * @param dispatch
 */
export const resetNotifications = (dispatch: any) => {
  dispatch({ type: RESET_NOTIFICATIONS });
};

/**
 * Call api to mark read all notifications when go to activities screen
 * @param dispatch
 */
export const readAllNotifications = (dispatch: any) => {
  dispatch(
    callApiAction({ method: 'put', api: config.rest.notifications() }, (response: any) => {
      const { status } = response;
      if (status === SUCCESS) {
      }
    }),
  );
};

/**
 * Reset new notification number to 0 when leave activities screen
 * @param dispatch
 */
export const readNotifications = (dispatch: any) => {
  dispatch({ type: READ_NOTIFICATIONS });
};
