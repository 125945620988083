// BOOK APPOINTMENT
export const APPOINTMENT_SET_STEP = 'APPOINTMENT_SET_STEP';
export const APPOINTMENT_UPDATE_SEARCH_TOPIC_KEYWORD = 'APPOINTMENT_UPDATE_SEARCH_TOPIC_KEYWORD';
export const APPOINTMENT_UPDATE_TOPICS = 'APPOINTMENT_UPDATE_TOPICS';
export const APPOINTMENT_UPDATE_QUESTIONS = 'APPOINTMENT_UPDATE_QUESTIONS';
export const APPOINTMENT_UPDATE_APPOINTMENT_DATA = 'APPOINTMENT_UPDATE_APPOINTMENT_DATA';
export const APPOINTMENT_SET_APPOINTMENT_TYPE = 'APPOINTMENT_SET_APPOINTMENT_TYPE';
export const APPOINTMENT_UPDATE_FREQUENCY_OPTIONS = 'APPOINTMENT_UPDATE_FREQUENCY_OPTIONS';
export const APPOINTMENT_UPDATE_ASSISTANT_DURATIONS_OPTIONS = 'APPOINTMENT_UPDATE_ASSISTANT_DURATIONS_OPTIONS';
export const APPOINTMENT_UPDATE_FREQUENCY_DATA = 'APPOINTMENT_UPDATE_FREQUENCY_DATA';
export const APPOINTMENT_CLEAR_BOOK_APPOINTMENT_DATA = 'APPOINTMENT_CLEAR_BOOK_APPOINTMENT_DATA';
export const APPOINTMENT_RESET = 'APPOINTMENT_RESET';

// VIEW APPOINTMENTS
export const VIEW_APPOINTMENTS_SET_LOADING = 'VIEW_APPOINTMENTS_SET_LOADING';
export const VIEW_APPOINTMENTS_UPDATE_LIST = 'VIEW_APPOINTMENTS_UPDATE_LIST';
export const VIEW_APPOINTMENTS_SET_MODE = 'VIEW_APPOINTMENTS_SET_MODE';
export const VIEW_APPOINTMENTS_SET_FILTER = 'VIEW_APPOINTMENTS_SET_FILTER';
export const VIEW_APPOINTMENTS_SET_CURRENT_PAGE = 'VIEW_APPOINTMENTS_SET_CURRENT_PAGE';
export const VIEW_APPOINTMENTS_UPDATE_STATISTICS = 'VIEW_APPOINTMENTS_UPDATE_STATISTICS';
export const VIEW_APPOINTMENTS_UPDATE_APPOINTMENT = 'VIEW_APPOINTMENTS_UPDATE_APPOINTMENT';
export const VIEW_APPOINTMENTS_REMOVE_APPOINTMENT = 'VIEW_APPOINTMENTS_REMOVE_APPOINTMENT';
export const VIEW_APPOINTMENTS_RESET = 'VIEW_APPOINTMENTS_RESET';
