import moment from 'moment';
import { includes, last } from 'lodash';

import { listHours } from 'config';
import {
  ADDITIONAL_HOURS_TIME_START,
  APPOINTMENT_REQUEST_TYPES,
  APPOINTMENT_REQUEST_TYPE_VALUES,
  DAYS_OFF,
  DEFAULT_SCHEDULE_TIME_START,
  DURATION_OPTIONS,
  FORMAT_DATETIME_CONVERT,
  MAX_SCHEDULE_TIME,
  MAX_SCHEDULE_TIME_START,
  MAX_TIME_SET_SCHEDULE_TODAY,
  SCHEDULE_OPTIONS,
} from 'configs/constants';
import { getKeyByValue } from './common';
import { OptionType } from 'types/common';

const { REQUESTED, ACCEPTED, FINISHED, CANCELLED } = APPOINTMENT_REQUEST_TYPES;
const { TODAY } = SCHEDULE_OPTIONS;

/**
 * Get valid schedule time input when check with min start time & max end time in the day
 *
 * @param timeInput Time input wanna check
 * @param defaultValue Return default value if time input is invalid
 *
 * @return string
 */
export const getValidScheduleTimeInput = (timeInput: string, defaultValue: string) => {
  return timeInput >= DEFAULT_SCHEDULE_TIME_START && timeInput <= MAX_SCHEDULE_TIME ? timeInput : defaultValue;
};

/**
 * Get schedule time options in book an appointment
 *
 * @param currentTime Current time has format HH:mm
 * @param options Options
 *
 * @return array
 */
export const getScheduleTimeOptions = (currentTime: string, options: any = {}) => {
  const { maxTime = MAX_SCHEDULE_TIME } = options;
  const minTimeStart = getValidScheduleTimeInput(currentTime, DEFAULT_SCHEDULE_TIME_START);
  const maxTimeEnd = getValidScheduleTimeInput(maxTime, MAX_SCHEDULE_TIME);

  const validTimes = listHours.filter((timeItem) => timeItem >= minTimeStart && timeItem <= maxTimeEnd);

  return validTimes.map((value) => ({ label: value, value }));
};

/**
 * Get schedule time start in book an appointment
 *
 * @param defaultTime Default time, if defaultTime has value => do not get by current time
 *
 * @return array
 */
export const getScheduleTimeStart = (defaultTime?: string) => {
  if (defaultTime) return defaultTime;

  let timeStart = moment().add(ADDITIONAL_HOURS_TIME_START, 'hours').format('HH:mm');
  timeStart = timeStart <= DEFAULT_SCHEDULE_TIME_START ? DEFAULT_SCHEDULE_TIME_START : timeStart;

  return listHours.find((timeItem) => timeItem >= timeStart && timeItem <= MAX_SCHEDULE_TIME_START) || timeStart;
};

/**
 * Get min schedule time end in book an appointment
 *
 * @param timeStart Time start value
 * @param additionalHours Additional hours
 *
 * @return string
 */
export const getScheduleMinTimeEnd = (timeStart: string = '', additionalHours: number = 1) => {
  const mmTimeStart = moment(`${moment().format('YYYY-MM-DD')} ${timeStart}`);

  return timeStart < MAX_SCHEDULE_TIME_START
    ? mmTimeStart.add(additionalHours, 'hours').format('HH:mm')
    : MAX_SCHEDULE_TIME;
};

/**
 * Get default time end by time start
 *
 * @param timeStart Time start value
 *
 * @return string
 */
export const getDefaultTimeEnd = (timeStart = '') => {
  return timeStart < '16:00' ? getScheduleMinTimeEnd(timeStart) : MAX_SCHEDULE_TIME;
};

/**
 * Get appointment times by type
 *
 * @param appointment Appointment data
 * @return object
 */
export const getAppointmentTimesByType = (appointment: any) => {
  const {
    appointment_status,
    appointment_time_begin: requestTimeStart,
    appointment_time_end: requestTimeEnd,
    appointment_time_confirms_to_start: acceptedTimeStart,
    appointment_time_confirms_to_stop: acceptedTimeEnd,
  } = appointment;
  const requestType = getKeyByValue(APPOINTMENT_REQUEST_TYPE_VALUES, appointment_status, REQUESTED);
  const isAccepted = acceptedTimeStart;

  switch (requestType) {
    case REQUESTED:
      return {
        timeStartSeconds: requestTimeStart,
        timeEndSeconds: requestTimeEnd,
      };
    case ACCEPTED:
    case FINISHED:
      return {
        timeStartSeconds: acceptedTimeStart,
        timeEndSeconds: acceptedTimeEnd,
      };
    case CANCELLED:
    default:
      return {
        timeStartSeconds: isAccepted ? acceptedTimeStart : requestTimeStart,
        timeEndSeconds: isAccepted ? acceptedTimeEnd : requestTimeEnd,
      };
  }
};

/**
 * Check date is days off or working days
 *
 * @param date Date wanna check
 * @return boolean
 */
export const checkDayOff = (date: string | number | Date) => includes(DAYS_OFF, moment(date).day());

/**
 * Get disabled date options
 *
 * @return array
 */
export const getDisabledDateOptions = () => {
  const currentTime = moment().format('HH:mm');
  const isDayOff = checkDayOff(moment().format('YYYY-MM-DD')) || currentTime > MAX_TIME_SET_SCHEDULE_TODAY;

  return isDayOff ? [TODAY] : [];
};

/**
 * Get default specific date
 *
 * @return Date
 */
export const getDefaultSpecificDate = () => {
  const nextDates = [1, 2, 3].map((nextDay) => new Date(moment().add(nextDay, 'd').format(FORMAT_DATETIME_CONVERT)));
  const firstValidDay = nextDates.find((checkingDate) => !checkDayOff(checkingDate));

  return firstValidDay;
};

/**
 * Handle show only working days
 *
 * @param date Date
 * @return boolean
 */
export const showOnlyWorkingDays = (date: Date) => !includes(DAYS_OFF, date?.getDay());

/**
 * Get duration options
 *
 * @param timeStart Time start
 * @return array
 */
export const getDurationOptions = (timeStart?: string) => {
  const lastItemDuration = last(DURATION_OPTIONS) as OptionType;
  const currentDate = moment().format('YYYY-MM-DD');
  const defaultDateUnix = moment(`${currentDate} 00:00`).unix();
  const timeStartUnix = timeStart ? moment(`${currentDate} ${timeStart}`).unix() : defaultDateUnix;
  const timeEndUnix = moment(`${currentDate} ${MAX_SCHEDULE_TIME}`).unix();

  const timeRequest = defaultDateUnix + timeEndUnix - timeStartUnix;
  const maxDuration = timeStart ? moment.unix(timeRequest).format('HH:mm') : lastItemDuration?.value;

  return DURATION_OPTIONS.filter((optionItem) => optionItem?.value >= '00:15' && optionItem?.value <= maxDuration);
};

/**
 * Get valid assistant duration options
 *
 * @param options List assistant duration options
 * @param timeStart Time start
 * @return array
 */
export const getValidAssistantDurationOptions = (options: any[], timeStart?: string) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const maxTimeEndUnix = moment(`${currentDate} ${MAX_SCHEDULE_TIME}`).unix();
  const timeStartUnix = moment(`${currentDate} ${timeStart}`).unix();

  return options.filter((optionItem: any) => timeStartUnix + optionItem?.seconds <= maxTimeEndUnix);
};

/**
 * convert from second to HH:MM:SS
 * @param value
 */
export const convertHMS = (value: any) => {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = parseInt('0' + hours);
  }
  if (minutes < 10) {
    minutes = parseInt('0' + minutes);
  }
  if (seconds < 10) {
    seconds = parseInt('0' + seconds);
  }
  if (hours < 1 && minutes > 0 && seconds > 0) return `${minutes}min ${seconds}s`;
  if (hours > 0 && minutes < 1 && seconds > 0) return `${hours}h ${seconds}s`;
  if (hours > 0 && minutes > 0 && seconds < 1) return `${hours}h ${minutes}min`;
  return `${hours}h ${minutes}min ${seconds}s`; // Return is HH : MM : SS
};
