import React from 'react';
import { useSelector } from 'react-redux';
import { addKMSToken } from 'utils';

interface PropTypes extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  isFitContent?: boolean;
}

const KMSIframe: React.FC<PropTypes> = (props) => {
  const { src = '', title = '', isFitContent = false, ...rest } = props;
  const access_token = useSelector((state: any) => state.auth.access_token);

  const newSource = addKMSToken(src, access_token);

  return <iframe {...rest} title={title} src={`${newSource}${isFitContent && src ? '#view=fitH' : ''}`} />;
};

export default KMSIframe;
