import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { styleOverflowDefault } from 'utils';

/**
 * Customize the CoreUI library
 */

export const Context = React.createContext({});

const getTransitionClass = (s) => {
  return s === 'entering' ? 'd-block' : s === 'entered' ? 'show d-block' : s === 'exiting' ? 'd-block' : '';
};

const CModal = (props) => {
  const {
    innerRef,
    show,
    centered,
    size,
    color,
    borderColor,
    fade,
    backdrop,
    closeOnBackdrop,
    onOpened,
    onClosed,
    addContentClass,
    onClose,
    className,
    scrollable,
    ...attributes
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [modalTrigger, setModalTrigger] = useState(false);
  const modalClick = (e) => e.target.dataset.modal && closeOnBackdrop && close();

  useEffect(() => {
    setIsOpen(show);

    if (show) {
      // document.body.style.overflow = 'hidden';
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    } else {
      // document.body.style.overflow = 'unset';
      document.getElementsByTagName('html')[0].style.overflowY = styleOverflowDefault;
    }
  }, [show]);

  const close = () => {
    onClose && onClose();
    setIsOpen(false);
  };

  const onEntered = () => {
    setModalTrigger(document.querySelector(':focus'));
    nodeRef.current.focus();
    onOpened && onOpened();
  };

  const onExited = () => {
    modalTrigger && modalTrigger.focus();
    onClosed && onClosed();
  };

  const modalClasses = `modal overflow-auto fade ${color ? `modal-${color}` : ''} ${className}`;

  const dialogClasses = `modal-dialog${scrollable ? ' modal-dialog-scrollable' : ''}${
    centered ? ' modal-dialog-centered' : ''
  }${size ? ` modal-${size}` : ''}`;

  const contentClasses = `modal-content${borderColor ? ` border-${borderColor}` : ''} ${addContentClass}`;

  const backdropClasses = `modal-backdrop${fade ? ' fade' : ''}${isOpen || fade ? ' show' : ''}`;

  const nodeRef = useRef(null);

  return (
    <div onClick={modalClick}>
      <Transition
        in={Boolean(isOpen)}
        onEntered={onEntered}
        onExited={onExited}
        timeout={fade ? 150 : 0}
        nodeRef={nodeRef}
      >
        {(status) => {
          let transitionClass = getTransitionClass(status);
          const classes = `${modalClasses} ${transitionClass}`;
          return (
            <div tabIndex={-1} role="dialog" className={classes} data-modal={true} ref={nodeRef}>
              <div className={dialogClasses} role="document">
                <div {...attributes} className={contentClasses} ref={innerRef}>
                  <Context.Provider value={{ close }}>{props.children}</Context.Provider>
                </div>
              </div>
            </div>
          );
        }}
      </Transition>
      {backdrop && isOpen && <div className={backdropClasses}></div>}
    </div>
  );
};

CModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  show: PropTypes.bool,
  centered: PropTypes.bool,
  size: PropTypes.oneOf(['', 'sm', 'lg', 'xl']),
  backdrop: PropTypes.bool,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  fade: PropTypes.bool,
  closeOnBackdrop: PropTypes.bool,
  onClose: PropTypes.func,
  addContentClass: PropTypes.string,
  scrollable: PropTypes.bool,
  style: PropTypes.any,
};

CModal.defaultProps = {
  backdrop: true,
  fade: true,
  closeOnBackdrop: true,
};

export default CModal;
