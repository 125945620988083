import React from 'react';
import './HBSwitch.scss';

interface Props {
  checked: boolean;
  onClick: any;
}

const HBSwitch: React.FC<Props> = (props) => {
  const { checked, onClick } = props;

  return (
    <div className={`HBSwitch${checked ? ' HBSwitch-checked' : ''}`} onClick={onClick}>
      <div className="HBSwitch-circle" />
    </div>
  );
};

export default HBSwitch;
