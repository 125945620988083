import React, { CSSProperties } from 'react';
import SelectLoadmore from './SelectLoadmore';

interface Props {
  className?: string;
  selected: any;
  setSelected: any;
  data: any[];
  loading?: boolean;
  placeholder?: string;
  maxHeight?: any;
  style?: CSSProperties;
}

const Select: React.FC<Props> = (props) => {
  let { selected, setSelected, loading = false, data, placeholder = '', ...rest } = props;

  selected = data.find((item) => item.id === selected) || null;
  const value = selected?.name || '';

  return (
    <SelectLoadmore
      {...rest}
      selected={selected}
      setSelected={(item: any) => setSelected(item?.id || null)}
      defaultValue={placeholder}
      full={true}
      value={value}
      data={data}
      loading={loading}
    />
  );
};

export default Select;
