import { CREATE_TOAST, REMOVE_TOAST } from './actions';

let toastKey = 0;

export const toastsReducer = (state = [], action: any) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_TOAST:
      // Not add duplicate toast
      if (
        state.findIndex(
          (item: any) =>
            item?.message === payload?.message ||
            (item?.message?.body && item?.message?.body === payload?.message?.body),
        ) !== -1
      )
        return state;

      toastKey += 1;
      return [...state, { ...payload, key: toastKey }];

    case REMOVE_TOAST:
      return state.filter((s: { key: number }) => s.key !== payload);

    default:
      return state;
  }
};
