import { APPOINTMENT_ASSISTANT_STEPS, APPOINTMENT_NURSE_STEPS, APPOINTMENT_TYPES } from 'configs/constants';
import { AssistantDurationOption } from 'types/appointments';
import { ActionTypes } from 'types/common';

import {
  APPOINTMENT_SET_STEP,
  APPOINTMENT_UPDATE_QUESTIONS,
  APPOINTMENT_RESET,
  APPOINTMENT_UPDATE_TOPICS,
  APPOINTMENT_UPDATE_APPOINTMENT_DATA,
  APPOINTMENT_SET_APPOINTMENT_TYPE,
  APPOINTMENT_UPDATE_FREQUENCY_OPTIONS,
  APPOINTMENT_UPDATE_FREQUENCY_DATA,
  APPOINTMENT_CLEAR_BOOK_APPOINTMENT_DATA,
  APPOINTMENT_UPDATE_SEARCH_TOPIC_KEYWORD,
  APPOINTMENT_UPDATE_ASSISTANT_DURATIONS_OPTIONS,
} from '../actionTypes';

const { NURSE } = APPOINTMENT_TYPES;
const defaultAppointmentData = {
  treatments: {
    apointmentId: '',
    productId: '',
    priceId: '',
    productName: '',
  },
  searchTopicKeyword: '',
  selectedTopic: null,
  isEmergencyTopic: false,
  cost: 0,
  adviceRelatives: [],
  isResetCountServeyIndex: true,
  surveyAnswers: {},
  scheduleData: {
    selectedOption: '',
    specificDate: '',
    timeStart: '',
    timeEnd: '',
    duration: '',
  },
  userInfo: {},
  frequencyData: {
    frequencyType: '',
    frequencyOption: '',
    frequencyOptionLabel: '',
  },
};
const initialState = {
  appointmentType: NURSE,
  step: APPOINTMENT_NURSE_STEPS.TOPICS,
  searchTopicKeyword: '',
  topics: [],
  questions: [],
  assistantDurations: [],
  frequencyOptions: [],
  highlightFrequencyOptions: [],
  appointmentData: defaultAppointmentData,
};

// Fake data
const dummyFrequencyOptions = [...Array.from(Array(30).keys())].map((index) => {
  const value = index + 1;
  const showLabel = value === 1 ? 'täglich' : value === 7 ? '1x im der Woche' : '';

  return {
    id: `${value}`,
    note: value === 1 ? 'täglich' : `alle ${value} Tage`,
    show_label: showLabel,
  };
});

const appointmentReducer = (state: any = initialState, action: ActionTypes) => {
  const { type, payload } = action;

  switch (type) {
    case APPOINTMENT_SET_STEP:
      return {
        ...state,
        step: payload,
      };
    case APPOINTMENT_UPDATE_SEARCH_TOPIC_KEYWORD: {
      return {
        ...state,
        searchTopicKeyword: payload,
      };
    }
    case APPOINTMENT_UPDATE_TOPICS: {
      return {
        ...state,
        topics: payload,
      };
    }
    case APPOINTMENT_UPDATE_QUESTIONS: {
      return {
        ...state,
        questions: payload,
      };
    }
    case APPOINTMENT_UPDATE_APPOINTMENT_DATA: {
      return {
        ...state,
        appointmentData: {
          ...state.appointmentData,
          ...payload,
        },
      };
    }

    case APPOINTMENT_CLEAR_BOOK_APPOINTMENT_DATA: {
      return {
        ...state,
        appointmentData: { ...defaultAppointmentData },
      };
    }
    case APPOINTMENT_SET_APPOINTMENT_TYPE: {
      return {
        ...state,
        appointmentType: payload,
        step: payload === NURSE ? APPOINTMENT_NURSE_STEPS.TOPICS : APPOINTMENT_ASSISTANT_STEPS.FREQUENCY_TYPE,
      };
    }
    case APPOINTMENT_UPDATE_FREQUENCY_OPTIONS: {
      const formatFrequencyOptions = dummyFrequencyOptions.map((item) => ({
        label: item?.note,
        value: item?.id,
        show_label: item?.show_label,
      }));
      const highlightFrequencyOptions = formatFrequencyOptions.filter((item) => item?.show_label) || [];

      return {
        ...state,
        frequencyOptions: formatFrequencyOptions,
        highlightFrequencyOptions: highlightFrequencyOptions.map((item) => ({ ...item, label: item?.show_label })),
      };
    }
    case APPOINTMENT_UPDATE_FREQUENCY_DATA: {
      const oldAppointmentData = { ...state.appointmentData };
      const { frequencyData: oldFrequencyData } = oldAppointmentData;

      return {
        ...state,
        appointmentData: {
          ...oldAppointmentData,
          frequencyData: {
            ...oldFrequencyData,
            ...payload,
          },
        },
      };
    }
    case APPOINTMENT_UPDATE_ASSISTANT_DURATIONS_OPTIONS: {
      return {
        ...state,
        assistantDurations: payload.map((option: AssistantDurationOption) => ({
          label: option?.title,
          value: option?.id,
          seconds: option?.seconds,
          price: option?.price,
        })),
      };
    }
    case APPOINTMENT_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default appointmentReducer;
