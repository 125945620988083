import PropTypes from 'prop-types';

/**
 * Customize the CoreUI library
 */

const CButtonClose = (props) => {
  const { children, className, buttonClass, innerRef, ...attributes } = props;

  const classes = `${buttonClass} ${className}`;

  return (
    <button className={classes} aria-label="Close" {...attributes} ref={innerRef}>
      {children || String.fromCharCode(215)}
    </button>
  );
};

CButtonClose.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  buttonClass: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  onClick: PropTypes.any,
};

CButtonClose.defaultProps = {
  buttonClass: 'close',
};

export default CButtonClose;
