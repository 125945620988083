import React, { CSSProperties } from 'react';
import './Checkbox.scss';

interface Props {
  checked?: boolean;
  onChange?: Function;
  disabled?: boolean;
  style?: CSSProperties;
}

const Checkbox: React.FC<Props> = ({ checked, onChange, disabled, style }) => {
  return (
    <div
      style={style}
      className={`Checkbox${disabled ? ' disabled' : ''}${checked ? ' checked' : ''}`}
      onClick={() => {
        if (!disabled) onChange?.(!checked);
      }}
    >
      {checked && <i className="fas fa-check" />}
    </div>
  );
};

export default Checkbox;
