import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { LANG_EN } from 'configs/constants';
import { notIsAdminGroups } from 'views/admin/extensions/configs';

interface Props {
  render: (props: any) => any;
  location?: any;
  path: string;
  exact?: boolean;
}

const PrivateRouteAdmin: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const groupName = useSelector((state: { auth: { group_name: string } }) => state.auth.group_name);
  const { render, location } = props;

  useEffect(() => {
    i18n.changeLanguage(LANG_EN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myRender = () => {
    if (!groupName)
      return () => (
        <Redirect
          to={{
            pathname: '/internal/admin/sign-in',
            state: { from: location },
          }}
        />
      );
    if (notIsAdminGroups.includes(groupName))
      return () => (
        <Redirect
          to={{
            pathname: '/404',
            state: { from: location },
          }}
        />
      );
    return render;
  };

  return <Route {...props} render={myRender()} />;
};

export default PrivateRouteAdmin;
