import React from 'react';
import { useSelector } from 'react-redux';
import { addKMSToken } from 'utils';
import { Document, pdfjs } from 'react-pdf';
import { Props } from 'react-pdf/dist/Document';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const KMSDocument: React.FC<Props> = (props) => {
  const { file = '', ...rest } = props;
  const access_token = useSelector((state: any) => state.auth.access_token);

  const newSource = addKMSToken(file, access_token);

  return <Document {...rest} file={newSource} />;
};

export default KMSDocument;
