import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { loadingReducer } from './loading/reducer';
import { sessionReducer } from './session/reducer';
import { toastsReducer } from './toasts/reducer';
import { authReducer } from './auth/reducer';
import { notificationsReducer } from './notifications/reducer';
import { newsfeedReducer } from './newsfeed/reducer';
import { settingsReducer } from './settings/reducer';
import { appointmentReducer } from './appointment';
import { viewAppointmentsReducer } from './viewAppointments';
import { paymentReducer } from './payment/reducer';
import { paymentStatusReducer } from './statusPayment/reducer';

import { watcherCallApi } from './callApi/saga';
import { all } from 'redux-saga/effects';
import { routerMiddleware } from 'react-router-redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import logger from 'redux-logger';

export const history = require('history').createBrowserHistory();

const reduxRouterMiddleware = routerMiddleware(history);

const rootReducer = combineReducers({
  loading: loadingReducer,
  session: sessionReducer,
  toasts: toastsReducer,
  auth: authReducer,
  notifications: notificationsReducer,
  newsfeed: newsfeedReducer,
  settings: settingsReducer,
  appointmentReducer,
  viewAppointmentsReducer,
  payment: paymentReducer,
  paymentStatus: paymentStatusReducer,
});

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([watcherCallApi()]);
}

const enhancers = [sagaMiddleware, reduxRouterMiddleware];

// if (process.env.NODE_ENV !== 'production') {
//   enhancers.push(logger);
// }

/**
 * Export type root of Reducer
 */
export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, applyMiddleware(...enhancers));

sagaMiddleware.run(rootSaga);
