import React from 'react';
import { useSelector } from 'react-redux';
import { addKMSToken } from 'utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  backgroundImage: string;
}

const KMSDiv: React.FC<Props> = (props) => {
  const { style = {}, backgroundImage, children, ...rest } = props;
  const access_token = useSelector((state: any) => state.auth.access_token);

  const newSource = addKMSToken(backgroundImage, access_token);

  return (
    <div {...rest} style={{ ...style, backgroundImage: `url("${newSource}")` }}>
      {children}
    </div>
  );
};

export default KMSDiv;
