import React, { forwardRef } from 'react';

import './HBButton.css';

export interface Props {
  className?: string;
  outline?: boolean;
  color?: 'petrol' | 'violet' | 'alert';
  style?: any;
  onClick?: any;
  disabled?: boolean;
  btnClassName?: string;
  type?: any;
  children: React.ReactNode;
}

const HBButton = forwardRef((props: Props, ref: any) => {
  const { className, color, style, outline, onClick, disabled, type, children, btnClassName } = props;
  let classNames = !color ? 'hb-btn' : `hb-btn-color hb-btn-${color}`;
  if (outline) classNames += ` outline`;
  if (btnClassName) classNames += ` ${btnClassName}`;

  return (
    <div className={className || ''}>
      <button ref={ref} type={type} className={classNames} onClick={onClick} disabled={disabled} style={style}>
        {children}
      </button>
    </div>
  );
});

export default HBButton;
