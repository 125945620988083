import { ISPAID, ISUNPAID } from './actions';

const initialState = { show: false, message: "", payment: false, title: "", type: "", role: "", isSuccess: false };

export const paymentStatusReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ISPAID:
            return { ...initialState };

        case ISUNPAID:
            return { ...state, show: true, message: action.payload.message, title: action.payload.title, type: action.payload.type, role: action.payload.role, isSuccess: action.payload.isSuccess };

        default:
            return state;
    }
};