import { CButton, CCol, CModalBody, CModalFooter, CRow } from '@coreui/react';
import React, { useState } from 'react';
import CModal from '../modal/CModal';
import config from '../../config';
import CModalHeader from '../modal/CModalHeader';
import { renderIconPassword } from 'views/admin/extensions/extensions';

interface Props {
  isVisible: boolean;
  title: string;
  type?: string;
  name?: string;
  leftButtonText: string;
  rightButtonText: string;
  callback: (password: string) => void;
  handleClose: () => void;
  customText?: string;
}

const ConfirmPopupPassword: React.FC<Props> = (props) => {
  const { isVisible, title, type, name, leftButtonText, callback, rightButtonText, handleClose, customText } = props;

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);

  const close = () => {
    setPassword('');
    setShowPassword(false);
    handleClose();
  };

  const ok = () => {
    close();
    callback(password);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (config.validate.validatePassword().test(password)) ok();
    }
  };

  return (
    <CModal centered show={isVisible} onClose={close} closeOnBackdrop={false}>
      <CModalHeader>{title}</CModalHeader>
      <CModalBody style={{ textAlign: 'center', padding: '20px 30px 24px 30px' }}>
        <div>
          <div className="text-center px-4">
            <div>
              {customText || (
                <>
                  Please enter your password to delete the {type}{' '}
                  <span style={{ color: 'var(--danger)', fontWeight: 600 }}>{name}</span>!
                </>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center mt-3 mx-3 justify-content-center" style={{ position: 'relative' }}>
            <input
              value={password}
              onChange={(e: any) => {
                if (!e.target.value.includes(' ')) setPassword(e.target.value);
              }}
              type={showPassword ? 'text' : 'password'}
              className="form-control"
              placeholder="Password"
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              maxLength={16}
              onKeyDown={handleKeyDown}
            />
            <div style={{ position: 'absolute', right: 0, top: 10 }}>
              {renderIconPassword(showPassword, () => setShowPassword(!showPassword))}
            </div>
          </div>
          {password !== '' && !focused && !config.validate.validatePassword().test(password) && (
            <div style={{ margin: '0.4rem 1rem 0 0', fontSize: '80%', color: '#e55353' }}>
              Your password must be at least 8 characters long with one letter, and a number
            </div>
          )}
        </div>
      </CModalBody>
      <CModalFooter style={{ justifyContent: 'center' }}>
        <CRow>
          <CCol>
            <CButton style={{ width: 120 }} color="danger" className="px-4" onClick={close}>
              {leftButtonText}
            </CButton>
          </CCol>
          <CCol>
            <CButton
              style={{ width: 120 }}
              color="info"
              className="px-4"
              disabled={!config.validate.validatePassword().test(password)}
              onClick={ok}
            >
              {rightButtonText}
            </CButton>
          </CCol>
        </CRow>
      </CModalFooter>
    </CModal>
  );
};

export default ConfirmPopupPassword;
