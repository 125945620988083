import React, { forwardRef } from 'react';
import './HBButtonSmall.css';

interface Props {
  className?: string;
  color?: 'petrol' | 'violet' | 'nightblue';
  style?: any;
  onClick?: any;
  disabled?: boolean;
  type?: any;
  light?: boolean;
  size?: 'sm' | 'md' | 'xl' | '';
  children?: React.ReactNode;
  outline?: boolean;
}

const HBButtonSmall = forwardRef(function CustomButton(props: Props, ref: any) {
  const { className, color, style, onClick, disabled, type, children, light, size = '', outline } = props;
  let classNames = !color ? 'hb-btn-small' : `hb-btn-small-color hb-btn-small-${color}`;
  if (className) classNames += ` ${className}`;
  if (outline) classNames += ' outline';

  return (
    <button
      ref={ref}
      type={type}
      className={`${classNames}${light ? ' light' : ''} ${size}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
});

export default HBButtonSmall;
