import { GOOGLE_CLIENT_ID as CLIENT_ID, GOOGLE_API_KEY as API_KEY } from 'config';
import { ObjectType } from 'types/common';
import { store } from 'store';
import { loadingRequest, loadingSuccess } from 'store/loading';

declare global {
  interface Window {
    gapi: any;
  }
}

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';
const gapi = window.gapi;

/**
 * Add event to google calendar
 *
 * @param eventData Event data
 * @param successCallBack Success callback
 * @param errorCallBack Error callback
 */
export const addEventToGoogleCalendar = async (
  eventData: ObjectType,
  successCallBack?: () => void,
  errorCallBack?: () => void,
) => {
  gapi.load('client:auth2', async () => {
    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    });

    try {
      const authInstance = await gapi.auth2.getAuthInstance();
      const signedIn = authInstance.isSignedIn.get();

      if (!signedIn) await authInstance.signIn();
      store.dispatch(loadingRequest());

      gapi.client.load('calendar', 'v3', () => {
        const request = gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: eventData,
        });

        request.execute((event: ObjectType) => {
          store.dispatch(loadingSuccess());
          successCallBack && successCallBack();
          window.open(event.htmlLink);
        });
      });
    } catch (e) {
      store.dispatch(loadingSuccess());
      errorCallBack && errorCallBack();
    }
  });
};
