import { includes } from 'lodash';
import { PAYMENT_STATUS } from 'configs/constants';

const { SUCCEEDED, PROCESSING } = PAYMENT_STATUS;

/**
 * Get key by value
 *
 * @param obj Object wanna get selected key
 * @param value Value compare
 * @param defaultValue Default value
 *
 * @return mixed
 */
export const getKeyByValue = (obj: any, value: any, defaultValue = '') => {
  return Object.keys(obj).find((key) => obj[key] === value) || defaultValue;
};

/**
 * Detect user agent is mobile or not
 *
 * @return Boolean
 */
export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
  (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1);

/**
 * Check item has paid or not
 *
 * @param paymentStatus Payment status
 * @return boolean
 */
export const isPaid = (paymentStatus: string) => {
  return includes([PROCESSING, SUCCEEDED], paymentStatus);
};
