import { useMemo } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'store';
import { capitalizeWords } from 'utils';
import { updateProfile } from './actions';

export default function useAuth() {
  const dispatch = useDispatch();
  const authReducer = useSelector((state: RootState) => state.auth, shallowEqual);

  const first_name = capitalizeWords(authReducer.first_name);
  const last_name = capitalizeWords(authReducer.last_name);
  const full_name = first_name + ' ' + last_name;

  return useMemo(
    () => ({
      ...authReducer,
      first_name,
      last_name,
      full_name,
      updateProfile: (payload: any) => dispatch(updateProfile(payload)),
    }),
    [dispatch, authReducer],
  );
}
