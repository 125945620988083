import React from 'react';
import { useSelector } from 'react-redux';
import { addKMSToken } from 'utils';

const KMSImg: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  const { src, alt = '', ...rest } = props;

  const access_token = useSelector((state: any) => state.auth.access_token);
  const newSource = addKMSToken(src || '', access_token);

  return <img src={newSource} alt={alt} {...rest} />;
};

export default KMSImg;
