import React, { useEffect, useState, Suspense } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import ScrollToTop from 'components/scrollToTop';
import PrivateRouteUser from './privateRoutes/PrivateRouteUser';
import PrivateRouteAdmin from './privateRoutes/PrivateRouteAdmin';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, signOut, updateProfile } from './store/auth/actions';

import { ls, jwt, loading } from './extensions';
import config, { adminRouteBase } from './config';
//import { messagingBrowser } from './init-fcm';
import axios from 'axios';
import { fetchNotifications, resetNotifications } from './store/notifications/actions';
import { changeSetting } from './store/settings/actions';
import { SUCCESS } from './store/callApi/actions';
import { addUserLS } from 'utils';
import { useApi } from 'store/callApi';

import './scss/style.scss';
import { initialDevicesBrowser, updateDevice } from 'utilities/notifications';
import { notIsAdminGroups } from 'views/admin/extensions/configs';

let messaging: any;
// Regiser service worker for push notification services
/*
if (messagingBrowser.isSupported()) {
  messaging = messagingBrowser();
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(function (err) {
        console.log('Service worker registration failed, error:', err);
      });
  }
}*/

// const notificationServices = async () => {
//     messaging.requestPermission()
//     .then(async function() {
//       const token = await messaging.getToken();
//       console.log(token);
//     })
//     .catch(function(err) {
//       console.log("Unable to get permission to notify.", err);
//     });
//   navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
// };

// User
const Layout = React.lazy(() => import('./views/user/containers/Layout'));
// Admin
const LayoutAdmin = React.lazy(() => import('./views/admin/containers/Layout'));
const SignInAdmin = React.lazy(() => import('./views/admin/signIn/SignIn'));
const ResetPasswordAdmin = React.lazy(() => import('./views/admin/resetPassword/ResetPassword'));

const TestBaseClass = React.lazy(() => import('./hbBaseClass/TestBaseClass'));

const clearLSLocations = ['sign-in', 'verify-email', 'forgot-password', 'recovery', 'reset-password'];

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { callApi } = useApi();

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [initSetting, setInitSetting] = useState({ loading: true, setting: false });
  const email = useSelector((state: any) => state.auth.email);
  const groupName = useSelector((state: any) => state.auth.group_name);
  const id_token = useSelector((state: any) => state.auth.id_token);
  const deviceId = useSelector((state: any) => state.auth.deviceId);
  const notifications = useSelector((state: any) => state.notifications);

  /**
   * Check local storage every 1s to determine whether the user is sign out from system in an other tab
   */
  useEffect(() => {
    const interval = setInterval(() => {
      const data = ls.get('data');
      if (!data) dispatch(signOut());
    }, 2000);

    return () => clearInterval(interval);
  }, [dispatch]);

  /**
   * Clear local storage if user go to page sign-in, verify-email
   */
  useEffect(() => {
    if (clearLSLocations.findIndex((item) => location.pathname?.includes(item)) !== -1) {
      dispatch(signOut());
      resetNotifications(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getSetting = () => {
    callApi(
      {
        method: 'get',
        api: config.rest.getSettings(),
      },
      (response: any) => {
        const { data, status } = response;
        setInitSetting({ loading: false, setting: true });
        if (status === SUCCESS) {
          dispatch(changeSetting(data.setting));
        }
      },
    );
  };

  useEffect(() => {
    if (!(groupName === 'user' || groupName === 'nurse' || groupName === 'assistant')) {
      setInitSetting({ loading: false, setting: false });
      return;
    }

    if (id_token) {
      // Generate device id if user signed in
      if (!deviceId) initialDevicesBrowser(email, dispatch);

      if (!initSetting.setting) {
        setInitSetting({ loading: true, setting: false });
        axios.defaults.headers.common['Authorization'] = id_token;
        getSetting();

        if (notifications.data.length === 0 && !notifications.loading && !notifications.full) {
          fetchNotifications(dispatch, 1);
        }
      }
    } else {
      setInitSetting({ loading: false, setting: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_token, groupName]);

  // Send device id to server if device id is generate successfully
  useEffect(() => {
    if (!deviceId) return;

    updateDevice(email, deviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  useEffect(() => {
    // init firebase push notifications

    if (messaging) {
      messaging
        .requestPermission()
        .then(async function () {
          const token = await messaging.getToken();
          // console.log('Quy', token);
          ls.set('push_token', token);
        })
        .catch(function (err: any) {
          console.log('Unable to get permission to notify.', err);
        });
      navigator.serviceWorker.addEventListener('message', (message) => {
        console.log(message);
        const data = message.data?.['firebase-messaging-msg-data']?.notification;
        if (data.title === 'Notification') {
          // const body = JSON.parse(data.body);
          // addNotification(dispatch, {
          //   ...body,
          //   id: Math.random().toString(),
          //   created_date: new Date().getTime() / 1000,
          //   is_read: false,
          // });
        }
      });
    }

    const data = ls.get('data');
    if (data) {
      try {
        const decoded = jwt.verify(data, config.app.secretKey);
        dispatch(signIn(decoded));
        axios.defaults.headers.common['Authorization'] = decoded.id_token;

        // Get profile and save to redux, local storage
        callApi(
          {
            method: 'get',
            api: config.rest.getProfileUser(),
          },
          ({ status, data }) => {
            if (status === SUCCESS) {
              const user = { ...data, avatar: data.avatar_thumbnail };
              addUserLS(user);
              dispatch(updateProfile(user));
            }
          },
        );

        if (!notIsAdminGroups.includes(decoded.group_name) && location.pathname === '/') history.push(adminRouteBase);
      } catch (error) {}
    }

    setIsFirstLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFirstLoading || initSetting.loading) return <div>{loading}</div>;

  return (
    <Suspense fallback={loading}>
      <ScrollToTop>
        <Switch>
          <Route exact path="/internal/sign-in" component={SignInAdmin} />
          <Route exact path="/internal/admin/sign-in" component={SignInAdmin} />
          <Route exact path="/internal/admin/reset-password" render={(props) => <ResetPasswordAdmin {...props} />} />
          <PrivateRouteAdmin path="/internal/admin" render={(props) => <LayoutAdmin {...props} />} />
          <PrivateRouteUser path="/internal" render={(props) => <Layout {...props} />} />
        </Switch>
      </ScrollToTop>
    </Suspense>
  );
};

export default App;
