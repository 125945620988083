import { CFormGroup, CInput, CInvalidFeedback, CLabel } from '@coreui/react';
import React from 'react';

/**
 * render the eye and eye-slash icon when password is shown or not
 * @param showPassword
 * @param onClick handle when user click this icon, change show status
 */
export const renderIconPassword = (showPassword: boolean, onClick: () => void) => (
  <div className="d-flex justify-content-center align-items-center cursor-pointer px-3">
    <i
      className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
      onClick={onClick}
      style={{ width: 18, height: 18, color: 'gray', display: 'flex', justifyContent: 'center' }}
    />
  </div>
);
