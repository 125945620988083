import { DEFAULT_VIEW_APPOINTMENTS_FILTER, VIEW_APPOINTMENTS_MODES } from 'configs/constants';
import { ActionTypes } from 'types/common';

import {
  VIEW_APPOINTMENTS_RESET,
  VIEW_APPOINTMENTS_SET_MODE,
  VIEW_APPOINTMENTS_SET_FILTER,
  VIEW_APPOINTMENTS_SET_CURRENT_PAGE,
  VIEW_APPOINTMENTS_UPDATE_LIST,
  VIEW_APPOINTMENTS_SET_LOADING,
  VIEW_APPOINTMENTS_UPDATE_STATISTICS,
  VIEW_APPOINTMENTS_REMOVE_APPOINTMENT,
  VIEW_APPOINTMENTS_UPDATE_APPOINTMENT,
} from '../actionTypes';

const { VIEW } = VIEW_APPOINTMENTS_MODES;

const initialState = {
  isLoading: false,
  mode: VIEW,
  timeFilter: DEFAULT_VIEW_APPOINTMENTS_FILTER,
  statistics: {},
  appointments: [],
  totalRecords: 0,
  currentPage: 1,
};

const viewAppointmentsReducer = (state: any = initialState, action: ActionTypes) => {
  const { type, payload } = action;

  switch (type) {
    case VIEW_APPOINTMENTS_SET_MODE:
      return {
        ...state,
        mode: payload,
      };
    case VIEW_APPOINTMENTS_SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case VIEW_APPOINTMENTS_SET_FILTER:
      return {
        ...state,
        timeFilter: payload,
      };
    case VIEW_APPOINTMENTS_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };
    case VIEW_APPOINTMENTS_UPDATE_LIST: {
      const { appointments = [], totalRecords, isReset = false } = payload;

      return {
        ...state,
        appointments: isReset ? [] : [...state.appointments, ...appointments],
        totalRecords: isReset ? 0 : totalRecords,
        currentPage: isReset ? 1 : state.currentPage,
      };
    }
    case VIEW_APPOINTMENTS_UPDATE_APPOINTMENT: {
      const { appointmentId, ...rest } = payload;

      return {
        ...state,
        appointments: state.appointments.map((appointmentItem: any) => {
          const hasAppointment = appointmentItem?.id === appointmentId;

          return hasAppointment ? { ...appointmentItem, ...rest } : appointmentItem;
        }),
      };
    }
    case VIEW_APPOINTMENTS_REMOVE_APPOINTMENT: {
      return {
        ...state,
        appointments: state.appointments.filter((appointmentItem: any) => appointmentItem?.id !== payload),
      };
    }
    case VIEW_APPOINTMENTS_UPDATE_STATISTICS: {
      return {
        ...state,
        statistics: payload,
      };
    }
    case VIEW_APPOINTMENTS_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default viewAppointmentsReducer;
