export const adminRouteBase = '/internal/admin';

export const types = ['admin', 'user', 'nurse'];
export const statuses = ['', 'Pending Approval', 'Disapproved', 'Approved', 'Pending Upload'];
export const langs = ['en', 'de'];
export const mappingGender: any = { '0': 'Male', '1': 'Female', '2': 'Other', '3': '-', null: '-' };

export const mappingPostureType: any = {
  standing: 'Standing exercises',
  lie: 'Lying down exercises',
  sit: 'Sitting exercises',
};

const {
  REACT_APP_BASE_URL,
  REACT_APP_WEBSOCKET,
  REACT_APP_CLOUDFRONT,
  REACT_APP_STRIPE_SECRET_KEY,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_STRIPE_ACCOUNT,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_STRIPE_PRODUCT_ID,
  REACT_APP_STRIPE_PRICE_ID,
  REACT_APP_STRIPE_RETURN_URL,
  REACT_APP_SENTRY_DSN,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_IS_HIDDEN_VERSION,
} = process.env;

const config = {
  api: {
    baseURL: REACT_APP_BASE_URL,
    websocket: REACT_APP_WEBSOCKET,
    cloudfront: REACT_APP_CLOUDFRONT,
  },
  app: {
    secretKey: 'hb2021',
    expiresIn: '30d',
    authenSecretKey: REACT_APP_STRIPE_SECRET_KEY,
    publicKey: REACT_APP_STRIPE_PUBLIC_KEY as string,
    stripeAccount: REACT_APP_STRIPE_ACCOUNT,
    stripeProductId: REACT_APP_STRIPE_PRODUCT_ID,
    stripePriceId: REACT_APP_STRIPE_PRICE_ID,
    stripeReturnUrl: REACT_APP_STRIPE_RETURN_URL,
    sentryDsn: REACT_APP_SENTRY_DSN,
    firebaseConfig: {
      apiKey: REACT_APP_FIREBASE_API_KEY,
      authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: REACT_APP_FIREBASE_APP_ID,
      measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
    isHiddenVersion: REACT_APP_IS_HIDDEN_VERSION === '1',
  },
  rest: {
    signInAdmin: () => '/api/v1/admin/auth/signin',
    signInUser: () => '/api/v1/user/auth/signin',
    signUpUser: () => '/api/v1/user/auth/signup',
    signOutUser: () => '/api/v1/user/auth/signout',
    signOutAdmin: () => '/api/v1/admin/auth/signout',
    refreshTokenAdmin: () => '/api/v1/admin/auth/refresh-token',
    refreshTokenUser: () => '/api/v1/user/auth/refresh-token',
    verifyToken: () => '/api/v1/verify-token',
    forceChangePassword: () => '/api/v1/admin/auth/force-change-password',
    getAllUsers: () => '/api/v1/admin/users',
    updateUser: (id: string) => `/api/v1/admin/users/${id}`,
    createUser: () => '/api/v1/admin/users',
    forgotPasswordUser: (email: string) => `/api/v1/user/auth/recovery?email=${email}`,
    forgotPasswordNurse: (email: string) => `/api/v1/nurse/auth/recovery?email=${email}`,
    confirmForgotPassword: () => '/api/v1/user/auth/recovery',
    resetPassword: (id: string) => `/api/v1/admin/users/${id}/refresh-password`,
    resendVerifyEmail: (email: string) => `/api/v1/verify-email?email=${email}`,
    verifyEmail: () => '/api/v1/verify-email',
    getAllMessages: () => '/api/v1/settings/messages',
    getStatistics: () => '/api/v1/admin/statistics',
    createMessage: () => '/api/v1/settings/messages',
    updateMessage: (id: string) => `/api/v1/settings/messages/${id}`,
    getNewsfeedCategories: () => '/api/v1/newsfeed/categories',
    getNewsfeedSearch: () => '/api/v1/newsfeed/search',
    getNewsfeedSeletectedCategories: () => '/api/v1/newsfeed/categories/selected',
    getNewsfeedArticles: (pageSize: number, page: number) =>
      `/api/v1/newsfeed/categories/articles?page_size=${pageSize}&page=${page}`,
    getNewsfeedArticlesById: (categoryId: string, pageSize: number, page: number) =>
      `/api/v1/newsfeed/categories/${categoryId}/articles?page_size=${pageSize}&page=${page}`,
    getNewsfeedFavoriteCategories: () => '/api/v1/newsfeed/favorite/selected',
    getNewsfeedFavoriteArticles: (pageSize: number, page: number) =>
      `/api/v1/newsfeed/favorite/categories/articles?page_size=${pageSize}&page=${page}`,
    getNewsfeedFavoriteArticlesById: (categoryId: string, pageSize: number, page: number) =>
      `/api/v1/newsfeed/favorite/categories/${categoryId}/articles?page_size=${pageSize}&page=${page}`,
    newsfeedFavoriteSetting: (articleId: string) => `/api/v1/newsfeed/favorite/${articleId}`,
    getTemporaryUploadLink: (fileName: string | number) => `/api/v1/upload/initialize?file_name=${fileName}`,
    getTemporaryUploadDocument: () => '/api/v1/upload/documents',
    createVideo: () => '/api/v1/admin/video',
    editVideo: (id: string) => `/api/v1/admin/video/${id}`,
    saveVideo: (videosId: string) => `/api/v1/mobility/videos/${videosId}/save`,
    unsaveVideo: (videosId: string) => `api/v1/mobility/videos/${videosId}/unsave`,
    getVideoById: (id: string) => `/api/v1/mobility/videos/${id}`,
    getAllMobilityCategories: (postureType: string) => `api/v1/mobility/categories?posture_type=${postureType}`,
    getSearchVideos: () => 'api/v1/mobility/videos',
    getRelatedVideos: (videoId: string, pageSize: number, page: number) =>
      `api/v1/mobility/videos/${videoId}/related?page=${page}&page_size=${pageSize}`,
    likeVideo: (videoId: string) => `/api/v1/mobility/videos/${videoId}/like`,
    unlikeVideo: (videoId: string) => `/api/v1/mobility/videos/${videoId}/unlike`,
    getComments: (videoId: string, page: number) =>
      `api/v1/mobility/videos/${videoId}/comments?page=${page}&page_size=10`,
    createComment: (videoId: string) => `api/v1/mobility/videos/${videoId}/comments`,
    updateComment: (videoId: string, commentId: string) => `/api/v1/mobility/videos/${videoId}/comments/${commentId}`,
    deleteComment: (videoId: string, commentId: string) => `/api/v1/mobility/videos/${videoId}/comments/${commentId}`,
    likeComment: (videoId: string, commentId: string) =>
      `/api/v1/mobility/videos/${videoId}/comments/${commentId}/like`,
    unlikeComment: (videoId: string, commentId: string) =>
      `/api/v1/mobility/videos/${videoId}/comments/${commentId}/unlike`,
    getReplies: (videoId: string, commentId: string, page: number) =>
      `api/v1/mobility/videos/${videoId}/comments/${commentId}/replies?page=${page}&page_size=10`,
    createReply: (videoId: string, commentId: string) =>
      `api/v1/mobility/videos/${videoId}/comments/${commentId}/replies`,
    updateReply: (videoId: string, commentId: string, replyId: string) =>
      `/api/v1/mobility/videos/${videoId}/comments/${commentId}/replies/${replyId}`,
    deleteReply: (videoId: string, commentId: string, replyId: string) =>
      `/api/v1/mobility/videos/${videoId}/comments/${commentId}/replies/${replyId}`,
    likeReply: (videoId: string, commentId: string, replyId: string) =>
      `/api/v1/mobility/videos/${videoId}/comments/${commentId}/replies/${replyId}/like`,
    unlikeReply: (videoId: string, commentId: string, replyId: string) =>
      `/api/v1/mobility/videos/${videoId}/comments/${commentId}/replies/${replyId}/unlike`,
    getVideos: () => '/api/v1/admin/video',
    getVideosOfTrainer: (trainerId: string) => `/api/v1/admin/trainers/${trainerId}/videos`,
    getProfileUser: () => `/api/v1/user/profile`,
    getListFollowerAndFollowings: (type: string, pageSize: number, pageNumber: number) =>
      `/api/v1/user/profile/follows?type=${type}&page_size=${pageSize}&page_number=${pageNumber}`,
    postFollowingUser: (userId: string) => `/api/v1/user/profile/follows/${userId}`,
    unfollowUser: (userId: string) => `/api/v1/user/profile/follows/${userId}`,
    editProfile: () => '/api/v1/user/profile',
    changePasswordUser: () => '/api/v1/user/auth/change-password',
    getUploadLink: (fileName: string) => `/api/v1/upload/avatar?file_name=${fileName}`,
    paymentOrderDetail: () => '/api/v1/payment/create-order',
    getVideosByHashTag: (hashTag: string, pageNumber: number, pageSize: number) =>
      `/api/v1/mobility/hashtags/${hashTag}?page=${pageNumber}&page_size=${pageSize}`,
    getHistoryWatch: () => '/api/v1/mobility/videos/history/watch',
    clearAllHistoryWatch: () => `/api/v1/mobility/videos/history/watch`,
    removeHistoryWatch: (videoId: any) => `/api/v1/mobility/videos/history/watch/${videoId}`,
    removeSearchHistoryKeyword: (keywordId?: any) => `/api/v1/mobility/videos/history/search/${keywordId}`,
    removeAllSearchHistoryKeywords: () => `/api/v1/mobility/videos/history/search`,
    getHistoriesKeywords: (keyword: string) => `/api/v1/mobility/videos/history/search?keyword=${keyword}`,
    getAllTreatments: () => '/api/v1/consulting/treatments',
    getSearchTreatments: () => '/api/v1/consulting/treatments/search',
    initialDevice: () => `/api/v1/devices`,
    getDevice: (deviceId: string) => `api/v1/devices/${deviceId}`,
    updateDevice: (deviceId: string) => `api/v1/devices/${deviceId}`,
    deleteDevice: (deviceId: string) => `/api/v1/devices/${deviceId}`,
    getProfileDocuments: () => '/api/v1/user/profile-documents',
    updateProfileDocuments: () => '/api/v1/user/profile-documents',
    getTemporaryUploadNurse: (fileName: string, type: string) =>
      `/api/v1/upload/documents-profile?file_name=${fileName}&type=${type}`,
    getAppointments: () => '/api/v1/consulting/appointments/users/search',
    deleteAppointment: (id: string) => `/api/v1/consulting/appointments/${id}`,
    getSurveys: (id: number) => `/api/v1/consulting/treatments/survey/${id}`,
    updateProfileAdmin: () => '/api/v1/admin/profile',
    changeAdminPassword: () => '/api/v1/admin/auth/change-password',
    createAppointment: () => '/api/v1/consulting/appointments',
    getListUsers: (searchName: any, page: any, pageSize: any) =>
      `/api/v1/consulting/friends?search_name=${searchName}&type=friend&page=${page}&page_size=${pageSize}`,
    getListUsersConfirm: (searchName: any, page: any, pageSize: any) =>
      `/api/v1/consulting/friends?search_name=${searchName}&type=confirm&page=${page}&page_size=${pageSize}`,
    getUserSearchName: (searchName: any) => `/api/v1/consulting/users?email=${searchName}`,
    getUserInformation: (userId: any) => `/api/v1/consulting/users/${userId}`,
    addFriend: (friendId: any) => `/api/v1/consulting/friends/${friendId}`,
    changeStatusFriend: (friendId: any) => `/api/v1/consulting/friends/${friendId}`,
    deleteFriend: (friendId: any) => `/api/v1/consulting/friends/${friendId}`,
    adminGetNewsfeedCategories: () => '/api/v1/admin/newsfeed/categories',
    adminNewsfeedCategory: (id: string) => `/api/v1/admin/newsfeed/categories/${id}`,
    adminGetNewsfeedArticles: (id: string) => `/api/v1/admin/newsfeed/categories/${id}/articles`,
    adminDeleteNewsfeedArticle: (categoryId: string, articleId: string) =>
      `/api/v1/admin/newsfeed/categories/${categoryId}/articles/${articleId}`,
    adminCreateNewsfeedArticle: (categoryId: string) => `/api/v1/admin/newsfeed/categories/${categoryId}/articles`,
    adminGetMobilityCategories: () => '/api/v1/admin/categories',
    adminDeleteMobilityCategory: (id: string) => `/api/v1/admin/categories/${id}`,
    adminCreateMobilityCategory: () => '/api/v1/admin/categories',
    adminUpdateMobilityCategory: (id: string) => `/api/v1/admin/categories/${id}`,
    adminGetConsultingCategories: () => '/api/v1/admin/consulting/treatments/survey/categories',
    adminConsultingCategory: (id?: string) =>
      `/api/v1/admin/consulting/treatments/survey/categories${id ? `/${id}` : ''}`,
    adminGetConsultingDiseases: () => '/api/v1/admin/consulting/treatments/survey/diseases',
    adminConsultingDisease: (id?: string) => `/api/v1/admin/consulting/treatments/survey/diseases${id ? `/${id}` : ''}`,
    confirmPassword: () => '/api/v1/admin/auth/confirm-password',
    getAllWebinars: (status: any, page: any, pageSize: any) =>
      `/api/v1/consulting/webinars?status=${status}&page=${page}&page_size=${pageSize}`,
    getWebinarById: (id: any) => `/api/v1/consulting/webinars/${id}`,
    nurseCreateMeeting: () => '/api/v1/consulting/nurses/webinar',
    followWebinar: (id: any) => `/api/v1/consulting/webinars/${id}/follow`,
    unFollowWebinar: (id: any) => `/api/v1/consulting/webinars/${id}/unfollow`,
    getInformationNurse: () => `/api/v1/user/profile-documents`,
    getDetailFeedbacks: (id: string, page: number) =>
      `/api/v1/consulting/appointments/users/feedback?nurse_id=${id}&page=${page}&page_size=10`,
    adminGetMobilityTrainers: () => '/api/v1/admin/trainers',
    adminMobilityTrainer: (id?: string) => `/api/v1/admin/trainers/${id || ''}`,
    getAllTrainers: (page: any, pageSize: any) =>
      `/api/v1/mobility/trainers?followed=2&page=${page}&page_size=${pageSize}`,
    getMyTrainers: (page: any, pageSize: any) =>
      `/api/v1/mobility/trainers?followed=1&page=${page}&page_size=${pageSize}`,
    trainerById: (id: any) => `/api/v1/mobility/trainers/${id}`,
    getVideoByTrainer: (idTrainer: any, page: any, pageSize: any) =>
      `/api/v1/mobility/trainers/${idTrainer}/videos?page=${page}&page_size=${pageSize}`,
    followTrainer: (idTrainer: any) => `/api/v1/mobility/trainers/${idTrainer}/follow`,
    unFollowTrainer: (idTrainer: any) => `/api/v1/mobility/trainers/${idTrainer}/unfollow`,
    unsaveALLMobility: () => `/api/v1/mobility/videos/unsave`,
    getSettings: () => `/api/v1/settings`,
    settings: () => `/api/v1/settings`,
    getProfileAssistant: () => `/api/v1/user/assistants/documents`,
    updateProfileAssistant: () => `/api/v1/user/assistants/documents`,
    appointmentsStatistics: () => '/api/v1/consulting/appointments/statistics',
    assistantTemporaryUpload: (file: any) => `/api/v1/upload/documents-profile?file_name=${file}&type=curriculum_vitae`,
    updateDeleteAppointment: () => '/api/v1/consulting/appointments/users',
    requestDocusign: () => '/api/v1/docusign/envelopes',
    signatureDocusignCompleted: () => '/api/v1/docusign/envelopes/save',
    payment: () => '/api/v1/payments',
    getTokenMeeting: (meetingId: string) => `/api/v1/consulting/meetings/${meetingId}/token`,
    getInfoMeeting: (meetingId: string) => `/api/v1/consulting/meetings/${meetingId}`,
    startMeeting: (meetingId: string) => `/api/v1/consulting/meetings/${meetingId}/start`,
    stopMeeting: (meetingId: string) => `/api/v1/consulting/meetings/${meetingId}/stop`,
    startRecordMeeting: (meetingId: string) => `/api/v1/consulting/meetings/${meetingId}/recording/start`,
    stopRecordMeeting: (meetingId: string) => `/api/v1/consulting/meetings/${meetingId}/recording/stop`,
    getRecordStatus: (meetingId: string) => `/api/v1/consulting/meetings/${meetingId}/recording/status`,
    getNurseAppointments: () => '/api/v1/consulting/appointments/nurses/search',
    nurseUpdateDeleteAppointment: () => '/api/v1/consulting/appointments/nurses',
    getAssistantDurationOptions: () => '/api/v1/consulting/blocks/time',
    getUserDetailAppointment: (id: string) => `/api`,
    getAssistantAppointments: () => '/api/v1/consulting/appointments/assistants/search',
    assistantUpdateDeleteAppointment: () => '/api/v1/consulting/appointments/assistants',
    getAppointmentDetail: (appointmentId: number | string, appointmentType: string) =>
      `/api/v1/consulting/appointments/${appointmentId}/${appointmentType}`,
    postUserFeedbackApi: () => '/api/v1/consulting/appointments/users/feedback',
    postNurseReportApi: (appointmentId: number | string) =>
      `/api/v1/consulting/appointments/${appointmentId}/nurses/report`,
    adminNotifications: (id?: string) => `/api/v1/admin/notifications${id ? `/${id}` : ''}`,
    adminPermissions: (id?: string) => `/api/v1/admin/permission${id ? `/${id}` : ''}`,
    adminGroups: (id?: string) => `/api/v1/admin/group${id ? `/${id}` : ''}`,
    adminPolicies: (id?: string) => `/api/v1/admin/policy${id ? `/${id}` : ''}`,
    adminPermissionPolicies: (permissionId: string, id?: string) =>
      `/api/v1/admin/permission/${permissionId}/policy${id ? `/${id}` : ''}`,
    adminGroupPermissions: (groupId: string) => `/api/v1/admin/group/${groupId}/permission`,
    notifications: (id?: string) => `api/v1/devices/notifications${id ? `/${id}` : ''}`,
    getMyPermissions: () => '/api/v1/admin/my/permission',
    adminInsurances: (id?: string) => `/api/v1/admin/insurances${id ? `/${id}` : ''}`,
    adminDipas: () => `/api/v1/admin/dipa`,
  },
  validate: {
    valueTypingRegexText: (e: any) => /^[0-9A-Za-z-äöüÄÖÜùûüÿàâæéèêëïîôœÙÛÜŸÀÂÆÉÈÊËÏÎÔŒß' ]*$/.test(e?.target?.value),
    valueTypingExpressionsName: (e: any) =>
      /^[A-Za-z-äöüÄÖÜùûüÿàâæéèêëïîôœÙÛÜŸÀÂÆÉÈÊËÏÎÔŒß' ]*$/.test(e?.target?.value),
    // validateEmail: () => /[A-Z0-9a-z_äöüÄÖÜùûüÿàâæéèêëïîôœÙÛÜŸÀÂÆÉÈÊËÏÎÔŒß']@[A-Z0-9a-z].[A-Z0-9a-z]/,
    validateEmail: () => /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    validatePassword: () => /(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9!@#$%^&*()\\-_=+{}|?>.<,:;]{8,16}/,
    valueTypingExpressionsPhone: (e: any) => /^[+0-9][0-9]*$/.test(e?.target?.value),
    valueTypingExpressionsNumber: (e: any) => /^[0-9]*$/.test(e?.target?.value),
    valueTypingExpressionsFullName: (e: any) =>
      /^([A-Za-z-äöüÄÖÜùûüÿàâæéèêëïîôœÙÛÜŸÀÂÆÉÈÊËÏÎÔŒß']+\s?)*$/.test(e?.target?.value),
  },
  messages: {
    signInFailure: 'Sign in failure.',
    signUpFailure: 'Sign up failure.',
    forceChangePasswordFailure: 'Change password failure.',
    createUserFailure: 'Create user failure.',
    updateUserFailure: 'Update user failure.',
    resetPasswordFailure: 'Reset password failure.',
    forgotPasswordFailure: 'Forgot password failure.',
    recoveryPasswordFailure: 'Recovery password failure.',
    resendVerifyEmailFailure: 'Resend verify email failure.',
    createMessageFailure: 'Create message failure.',
    updateMessageFailure: 'Update message failure.',
  },
  socketEvent: {
    processedVideo: 'check_upload_status',
    processedTrainerIntro: 'check_upload_intro_trainer_status',
  },
};

export const topicsScrollSetting = {
  alignCenter: false,
  clickWhenDrag: false,
  dragging: true,
  hideArrows: true,
  hideSingleArrow: true,
  inertiaScrolling: false,
  inertiaScrollingSlowdown: 0.25,
  rtl: false,
  scrollBy: 0,
  scrollToSelected: false,
  transition: 0.4,
  useButtonRole: true,
  wheel: false,
  menuClass: 'hb-scroll-menu-wrapper',
};

export const listHours = Array.from(
  { length: 24 * 4 + 1 },
  (_, id) => ('0' + Math.floor(id / 4)).substr(-2) + ':' + ('0' + Math.floor(id % 4) * 15).substr(-2),
);

// const listHours = [];
// for (let i = 0; i < 24; i++)
//   for (let j = 0; j < 4; j++) listHours.push(`${('0' + i).substr(-2)}:${('0' + j * 15).substr(-2)}`);

export const GOOGLE_CLIENT_ID = REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_API_KEY = REACT_APP_GOOGLE_API_KEY;

export type ItemNotificationType = {
  type: 'friend_requested' | 'friend_request_accepted';
  title: string;
  body: string;
  is_new: boolean;
  id: string;
  is_read: boolean;
  created_date: number;
  image: string;
  body_json: string;
  sender: {
    email: string;
    first_name: string;
    last_name: string;
    avatar: string | null;
  };
  receiver: {
    email: string;
    first_name: string;
    last_name: string;
    avatar: string | null;
  };
};

export type ItemActivityType = {
  id: string;
  type: 'general' | 'article_of_week' | 'new_video' | 'upcoming_appointment' | 'trainer' | 'new_report';
  header?: {
    title: string;
    content?: string;
  };
  title?: any;
  body?: any;
  body_json?: any;
  is_read?: boolean;
  type_payment?: 'mobility' | 'appointment';
  setNoSource?: Function;
};

export const listUserStatus = [
  null,
  { id: 3, name: 'Approved' },
  { id: 1, name: 'Pending Approval' },
  { id: 2, name: 'Disapproved' },
  { id: 4, name: 'Pending Upload' },
];

export const listStatus = [
  null,
  { id: 'Draft', name: 'Draft' },
  { id: 'Disable', name: 'Disable' },
  { id: 'Posted', name: 'Posted' },
  { id: 'Uploading', name: 'Uploading' },
  { id: 'Processing', name: 'Processing' },
  { id: 'Upload Failed', name: 'Upload Failed' },
];

export const listDiseaseStatus = [null, { id: 1, name: 'Show' }, { id: 0, name: 'Hide' }];

export const listPostureType = [
  null,
  { id: 'standing', name: 'Standing exercises' },
  { id: 'sit', name: 'Sitting exercises' },
  { id: 'lie', name: 'Lying down exercises' },
];

export default config;
