import axios from 'axios';
import config from 'config';
import { ls } from 'extensions';
import { clearLS } from 'utils';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const ADD_STRIPE_PAYMENT = 'ADD_STRIPE_PAYMENT';
export const REMOVE_STRIPE_PAYMENT = 'REMOVE_STRIPE_PAYMENT'
export const UPDATE_STRIPE_PAYMENT = 'UPDATE_STRIPE_PAYMENT'

export const signIn = (payload: { username: string; password: string }) => {
  return { type: SIGN_IN, payload };
};

export const signOut = () => {
  const deviceId = ls.get('device_id');
  try {
    if (deviceId) axios.delete(config.rest.deleteDevice(deviceId));
  } catch { }
  ls.remove('push_token');
  ls.remove('registered');
  ls.remove('device_id');
  clearLS();

  return { type: SIGN_OUT };
};

export const refreshToken = (payload: { id_token: string; access_token: string }) => {
  return { type: REFRESH_TOKEN, payload };
};

export const updateProfile = (payload: any) => {
  return { type: UPDATE_PROFILE, payload };
};

export const addStripe = (payload: any) => {
  return { type: ADD_STRIPE_PAYMENT, payload };
};

export const updateStripe = (payload: any) => {
  return { type: UPDATE_STRIPE_PAYMENT, payload };
};

export const removeStripe = (payload: any) => {
  return { type: REMOVE_STRIPE_PAYMENT, payload };
};