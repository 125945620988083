import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { store, history } from './store';
import axios from 'axios';
import config from './config';
import { BrowserRouter, Router } from 'react-router-dom';
import Loading from './components/loading/Loading';
import Toaster from './components/toast/Toaster';
import SessionCheck from './components/session/SessionCheck';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isDev } from './utils';
import metadata from './metadata.json';
// import BackToTop from './components/backToTop/BackToTop';
import ConnectSocket from 'components/connectSocket/ConnectSocket';
import de from 'date-fns/locale/de';
import { registerLocale } from 'react-datepicker';

registerLocale('de', de);

axios.defaults.baseURL = config.api.baseURL;

const { buildMajor, buildMinor, buildHotfix, buildNumber } = metadata;
const version = `${buildMajor}.${buildMinor}.${buildHotfix} (${buildNumber})`;

const Index = () => {
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    console.log(`%cVersion: ${version}`, 'color: blue; font-size: 28px');

    const init = async () => {
      let translationEN: any;
      let translationDE: any;

      const initI18n = () => {
        i18n
          .use(detector)
          .use(initReactI18next)
          .init({
            resources: {
              en: {
                translation: translationEN,
              },
              de: {
                translation: translationDE,
              },
            },
            lng: 'en',
            fallbackLng: 'en',
            keySeparator: false,
            interpolation: {
              escapeValue: false,
            },
          });

        setInitializing(false);
      };

      if (!isDev()) {
        config.app.sentryDsn &&
          Sentry.init({
            dsn: config.app.sentryDsn,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
          });

        // const loadEN = async () => {
        //   const response = await axios.get('https://hb-storage-1.s3.eu-central-1.amazonaws.com/translation_en.json');
        //   translationEN = response.data;
        // };

        // const loadDE = async () => {
        //   const response = await axios.get('https://hb-storage-1.s3.eu-central-1.amazonaws.com/translation_de.json');
        //   translationDE = response.data;
        // };

        // Promise.all([loadEN(), loadDE()]).then(() => {
        //   initI18n();
        // });
      } else {
        // translationEN = require('./locales/en/translation.json');
        // translationDE = require('./locales/de/translation.json');
        // initI18n();
      }

      translationEN = require('./locales/en/translation.json');
      translationDE = require('./locales/de/translation.json');
      initI18n();
    };

    init();
  }, []);

  if (initializing) return <div />;

  return (
    <React.Fragment>
      <Router history={history}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
            {/* <MultiLanguage /> */}
            <ConnectSocket />
            <SessionCheck />
            <Loading />
            <Toaster />
          </BrowserRouter>
        </Provider>
        {/* <BackToTop /> */}
      </Router>

      {!config.app.isHiddenVersion && <div className="version">v{version}</div>}
    </React.Fragment>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));
