import { CButton, CCol, CModalBody, CModalFooter, CRow } from '@coreui/react';
import CModal from '../modal/CModal';
import CModalHeader from '../modal/CModalHeader';
import React from 'react';

interface ConfirmPopupProps {
  isVisible?: boolean;
  title?: string;
  content?: string;
  renderContent?: () => void;
  leftButtonText?: string;
  rightButtonText?: string;
  leftButtonOnPress?: () => void;
  rightButtonOnPress?: () => void;
  handleClose?: () => void;
  btnWidth?: number;
  hideTitle?: boolean;
  centered?: boolean;
}

export const ConfirmPopup: React.FC<ConfirmPopupProps> = (props) => {
  const {
    btnWidth,
    hideTitle,
    centered,
    isVisible,
    handleClose,
    renderContent,
    leftButtonText,
    leftButtonOnPress,
    rightButtonText,
    rightButtonOnPress,
  } = props;

  let { content } = props;

  if (typeof content === 'object') content = JSON.stringify(content);

  return (
    <CModal centered show={isVisible} onClose={handleClose} closeOnBackdrop={false}>
      {!hideTitle && <CModalHeader>{props.title}</CModalHeader>}
      <CModalBody style={centered ? { textAlign: 'center', padding: 20 } : {}}>
        {content || renderContent?.()}
      </CModalBody>
      <CModalFooter style={centered ? { justifyContent: 'center' } : {}}>
        <CRow>
          {leftButtonText && (
            <CCol>
              <CButton
                style={btnWidth ? { width: btnWidth } : {}}
                color="danger"
                className="px-4"
                onClick={() => leftButtonOnPress?.()}
              >
                {leftButtonText}
              </CButton>
            </CCol>
          )}
          {rightButtonText && (
            <CCol>
              <CButton
                style={btnWidth ? { width: btnWidth } : {}}
                color="info"
                className="px-4"
                onClick={() => rightButtonOnPress?.()}
              >
                {rightButtonText}
              </CButton>
            </CCol>
          )}
        </CRow>
      </CModalFooter>
    </CModal>
  );
};
