import React, { useCallback, useEffect, useRef, useState } from 'react';
import './MaxLine.scss';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

interface Props {
  title?: string;
  numberOfLines?: number;
  text: string;
}

const MaxLine: React.FC<Props> = (props) => {
  const { title, numberOfLines = 2, text } = props;

  const ref = useRef<any>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(ref?.current?.scrollHeight > ref?.current?.clientHeight);
  }, [ref, text]);

  const Wrapper = useCallback(
    (p: any) =>
      showTooltip ? (
        <Tippy arrow={false} content={title || text}>
          {p.children}
        </Tippy>
      ) : (
        <div>{p.children}</div>
      ),
    [showTooltip, text],
  );

  return (
    <Wrapper>
      <div style={{ WebkitLineClamp: numberOfLines }} ref={ref} className="hb-max-line">
        {text}
      </div>
    </Wrapper>
  );
};

export default MaxLine;
