import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from './CModal';
import CButtonClose from './CButtonClose';

/**
 * Customize the CoreUI library
 */

const CModalHeader = (props) => {
  const { className = '', innerRef, closeButton, ...attributes } = props;

  const { close } = useContext(Context);

  const classes = `${className} modal-header`;

  return (
    <div className={classes} {...attributes} ref={innerRef}>
      {props.children}
      {closeButton && <CButtonClose onClick={close} />}
    </div>
  );
};

CModalHeader.propTypes = {
  style: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  closeButton: PropTypes.bool,
};

CModalHeader.defaultProps = {
  tag: 'header',
};

export default CModalHeader;
