import { CSpinner, CPagination } from '@coreui/react';
import { KMSImg, MaxLine } from 'components';
import moment from 'moment';
import mainLogo from 'assets/images/main-logo.png';
import { mappingGender } from 'config';
import { CSSProperties } from 'react';

export { default as defaultAvatar } from './assets/images/default-avatar.png';

export const ls = require('local-storage');
export const jwt = require('jsonwebtoken');

export const loading = (
  <div className="spin--loading">
    <CSpinner
      // color="#36d7b7"
      style={{
        width: '4.5rem',
        height: '4.5rem',
        margin: 'auto',
        border: '0.4em solid var(--nightblue-20)',
        borderRightColor: 'transparent',
      }}
    />
  </div>
);

export const loadingSmall = (
  <div className="spin--loading-small">
    <CSpinner
      style={{
        width: '2rem',
        height: '2rem',
        margin: 'auto',
        border: '0.28em solid #959697',
        borderRightColor: 'transparent',
      }}
    />
  </div>
);

export const ArrowLeft = <i className="fas fa-angle-left" />;
export const ArrowRight = <i className="fas fa-angle-right" />;

// export const defaultAvatar = 'https://i.imgur.com/qyn9t8L.png';
export const videoNoThumbnail = 'https://i.imgur.com/PbEoHqI.jpg';
export const defaultTrainer = 'https://i.imgur.com/pHqsRLz.png';

export const pagination = (totalPages: number, page: number, pageChange: any, total: number, pageSize: number = 10) => {
  const first = 1 + pageSize * (page - 1);
  let last = page * pageSize;
  if (last > total) last = total;

  return totalPages > 0 ? (
    <div className="d-flex" style={{ justifyContent: 'space-between' }}>
      <div style={{ width: 112 }} />
      {totalPages > 1 ? (
        <CPagination
          activePage={page}
          onActivePageChange={pageChange}
          pages={totalPages}
          arrows={true}
          doubleArrows={true}
          align="center"
        />
      ) : (
        <div />
      )}
      <div>
        {first} - {last} of {total} item{total > 1 ? 's' : ''}
      </div>
    </div>
  ) : null;
};

export const logoMain = <img src={mainLogo} alt="logo" style={{ width: 168, objectFit: 'cover' }} />;

export const adminDeleteContent = (text1: string, name?: string, text2?: string) => {
  return () => (
    <div className="text-center px-4">
      <div>
        {text1}
        {name ? <span style={{ color: 'var(--danger)', fontWeight: 600 }}> {name}</span> : null}?
      </div>
      <div>{text2}</div>
    </div>
  );
};

/**
 * Check empty category name
 *
 * @param categoryName Category name
 * @return boolean
 */
export const isEmptyCategoryName = (categoryName?: string) => {
  return !categoryName || categoryName === '-' || categoryName === 'Non category';
};

/**
 * Replace Non category by - character
 * @param categoryName name of mobility category
 */
export const createCategoryHashtag = (categoryName: string) => {
  let hasCategory = true;

  if (isEmptyCategoryName(categoryName)) hasCategory = false;
  // return <span style={{ margin: '0 20px' }}>-</span>;
  return (
    <div style={{ visibility: hasCategory ? 'visible' : 'hidden' }} className="hb-card-hashtag">
      {categoryName}
    </div>
  );
};

export type TableFieldsType = {
  key: string;
  label?: any;
  _style?: CSSProperties;
  _classes?: string;
  disableMaxLine?: boolean;
  type?: 'image' | 'datetime' | 'date' | 'gender' | 'avatar';
  renderContent?: (item: any) => any;
  defaultTitle?: boolean;
  sorter?: boolean;
};

/**
 * Create render content for table
 * @param fields list fields of table
 */
export const renderFields = (fields: TableFieldsType[]) => {
  let newFields: any = {};
  fields.forEach((item) => {
    newFields[item.key] = (itemData: any) => {
      let newContent = itemData[item.key];
      let newRenderContent;
      switch (item.type) {
        case 'image':
          newRenderContent = newContent ? (
            <KMSImg src={newContent} alt="content" style={{ width: 60, height: 40 }} />
          ) : null;
          break;

        case 'gender':
          newRenderContent = mappingGender[newContent];
          break;

        case 'date':
        case 'datetime':
          newRenderContent = !newContent
            ? '-'
            : moment(newContent * 1000).format(`DD/MM/YYYY${item.type === 'datetime' ? ' HH:mm' : ''}`);
          break;

        default:
          if (item.renderContent) newRenderContent = item.renderContent(itemData);
          else newRenderContent = newContent;
      }
      return (
        <td style={item._style}>
          {item.disableMaxLine ? (
            newRenderContent
          ) : (
            <MaxLine title={item.defaultTitle ? newContent : undefined} text={newRenderContent} />
          )}
        </td>
      );
    };
  });
  return newFields;
};
