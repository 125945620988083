import { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { callApiAction, InputApiType, OutputApiType } from './actions';

export default function useApi() {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      callApi: (payload: InputApiType, callback?: (result: OutputApiType) => void) =>
        dispatch(callApiAction(payload, callback)),
    }),
    [dispatch],
  );
}
