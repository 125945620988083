import axios from 'axios';
import config from 'config';
import { ACTIVITY_TYPES } from 'configs/constants';
import GlobalEvent from 'js-events-listener';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'store/auth';
import { addStripe } from 'store/auth/actions';
import { addNotification, readAllNotifications } from 'store/notifications/actions';
import { showPopup } from 'store/statusPayment/actions';
import { createToast } from 'store/toasts/actions';
import { timestampToDate } from 'utils';

// const MAX_RECONNECT_TIME = 5;
//const { SUCCEEDED, FAIL } = PAYMENT_STATUS;

const TYPE_EVENT = {
  SUCCEEDED: 'payment_intent.succeeded',
  FAIL: 'payment_intent.payment_failed',
};

// const ERR_TOKEN =
//   'eyJraWQiOiJMdGN4cGl1UHh5YXNcL0RXM1BobUZPUHVJN3U4U0NzXC9MU3RJU1Z2QkVPa0U9IiwiYWxnIjoiUlMyNTYifQ.eyJvcmlnaW5fanRpIjoiOWZkM2YwZWEtOTEzNS00YzQ2LThhZDQtMDY4ZDFhMmEwMTc4Iiwic3ViIjoiZmFjZTIwOWQtZDAxNS00MDIxLWFjNTktNzhkYzI4ZTBhOWYxIiwiZXZlbnRfaWQiOiI4M2E1M2UyZS02ZGNlLTQwMjItYTE4ZC1hMDE0NjgxY2IzNTQiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIiwiYXV0aF90aW1lIjoxNjI1MjEzNDk1LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV8wMzJyUnFuNEIiLCJleHAiOjE2MjUyMTcwOTUsImlhdCI6MTYyNTIxMzQ5NSwianRpIjoiZDk1NThhNmEtOWEzNi00OWRjLWFhOTgtZDFiZGY3YWFlNTUwIiwiY2xpZW50X2lkIjoiMmt1aXU0OWdoMGhmMGJzZTkxMTJoNHZmaHYiLCJ1c2VybmFtZSI6ImZhY2UyMDlkLWQwMTUtNDAyMS1hYzU5LTc4ZGMyOGUwYTlmMSJ9.nj6KTM3iRr68lKqWHAf0853lB-n9KEYMWYAjvW2dWURM0crZXr9pCGJN6-hoeINZIK6ycF75ZKVoNUi7PSH6h_Y867OGIWEd30UdtenNZgOqRnQQKc7rs_F2IS1YwVxiDXueEOXn3BZhq56-z3VbV8W-drqDkpV5dFQvTN6o3hSDrKnfQVgmehbqjiT8MvZZCuZfdPl1K5BI3SAuwi0BJf0MTOlTqqr86EWhAlzYVeirgEhR47CbqdGEhiNOjXVBl_d57UauptIh6XPmyimyCbN1VkZDMscAVp-FOgPIAQFjscswzonnvWiDZ6TjOUBWko_2W5gKMdBVscTx38Su0Q';

const getTime = () => moment().format('HH:mm:ss');

const ConnectSocket: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useAuth();

  const userData = useRef({ group_name: auth.group_name, refresh_token: auth.refresh_token, user_id: auth.user_id });

  const accessTokenRef = useRef(auth.access_token);

  const [autoReconnect, setAutoReconnect] = useState(0);
  const [firstTime, setFirstTime] = useState(true);

  const socket = useRef<any>(null);
  // const reconnectTime = useRef(MAX_RECONNECT_TIME);
  const allowConnect = useRef(true);
  const isRefreshing = useRef(false);
  const readNotificationsTimeout = useRef<any>(null);

  // Call api read all when receive socket noti and user in activities screen
  const readNotificationsAction = () => {
    if (window.location.pathname === '/activity') {
      clearTimeout(readNotificationsTimeout.current);
      readNotificationsTimeout.current = setTimeout(() => {
        readAllNotifications(dispatch);
      }, 500);
    }
  };

  /**
   * Close connect the socket
   */
  const closeSocket = () => {
    socket.current?.close?.();
    socket.current = null;
  };

  /**
   * Init connect socket, all handle event functions
   */
  const handleSocket = () => {
    if (!accessTokenRef.current || socket.current) return;
    if (!allowConnect.current || isRefreshing.current) return;

    allowConnect.current = false;

    setInterval(() => (allowConnect.current = true), 800);

    const client = new WebSocket(`${config.api.websocket}?token=${accessTokenRef.current}`);

    /**
     * When connect error, refresh token and reconnect
     */
    client.onerror = (e) => {
      console.log('Connect error: ', e);
      // reconnectTime.current = reconnectTime.current - 1;
      const { group_name, refresh_token } = userData.current;
      if (!group_name || !refresh_token) return;
      isRefreshing.current = true;

      axios
        .post(group_name.includes('admin') ? config.rest.refreshTokenAdmin() : config.rest.refreshTokenUser(), {
          refresh_token: refresh_token,
        })
        .then((response) => {
          isRefreshing.current = false;
          accessTokenRef.current = response.data.data?.access_token;
          setTimeout(() => setAutoReconnect((autoReconnect) => autoReconnect + 1), 300);
        })
        .catch((error) => console.log(error));
    };

    client.onopen = () => {
      console.log(`Connected at ${getTime()}`);
      client.send('ping');

      socket.current = client;
    };

    /**
     * If socket is disconnected, reconnect after 1s
     */
    client.onclose = () => {
      console.log(`Disconnected at ${getTime()}`);

      socket.current = null;
      setTimeout(() => {
        // Only reconnect if not disconnect by a error, if error => isRefreshing = true
        if (!isRefreshing.current) setTimeout(() => setAutoReconnect((autoReconnect) => autoReconnect + 1), 950);
      }, 50);
    };

    /**
     * When receive message from server
     * @param e
     */
    client.onmessage = (e: any) => {
      try {
        // console.log('Message before parse: ', e?.data);
        let messageStr = e?.data?.replace(/'/g, '"');
        const message = JSON.parse(messageStr);
        const user_id: string[] = message.user_id || [];

        const show = user_id.includes(userData.current.user_id);

        console.log(`Received message at ${getTime()}, Show toast: ${show}`, message);
        if (!show) return;

        if (message?.topic === 'activity' || message?.topic === 'webhook_stripe') {
          const messageBody = message?.body;
          const { title = '', body = '', body_json = {} } = messageBody;
          if (body_json.type_event === TYPE_EVENT.SUCCEEDED) {
            // return
            dispatch(showPopup(body, title, body_json.type_payment, body_json.appointment_role, true));
          } else if (body_json.type_event === TYPE_EVENT.FAIL) {
            // return
            dispatch(showPopup(body, title, body_json.type_payment, body_json.appointment_role));
          }
          dispatch(
            createToast({
              title: title,
              body:
                body +
                (messageBody.type === ACTIVITY_TYPES.NEW_REPORT
                  ? ` ${timestampToDate(body_json.created_date || 0)}`
                  : ''),
              violet: messageBody.type === ACTIVITY_TYPES.NEW_VIDEO || !!body_json.payment?.customer_id,
            }),
          );
          const { payment } = body_json;
          if (payment) dispatch(addStripe(payment));
          addNotification(dispatch, messageBody);

          readNotificationsAction();
        } else {
          GlobalEvent.emit(message?.topic, {
            data: message,
          });
        }
      } catch (e) {
        console.log('Connect socket error: ', e);
      }
    };
  };

  // Re connect socket
  useEffect(() => {
    handleSocket();
  }, [dispatch, autoReconnect]);

  // Close socket when sign out or reset user data when auth is updated
  useEffect(() => {
    if (!auth.access_token) {
      accessTokenRef.current = '';
      setFirstTime(true);
      closeSocket();
    }
    userData.current = { group_name: auth.group_name, refresh_token: auth.refresh_token, user_id: auth.user_id };

    // When access_token is updated, connect websocket, but only use for the first time
    if (firstTime && auth.access_token) {
      accessTokenRef.current = auth.access_token;
      // Error token for test refresh
      // accessTokenRef.current = ERR_TOKEN;
      setFirstTime(false);
      setTimeout(() => setAutoReconnect((autoReconnect) => autoReconnect + 1), 300);
    }
  }, [dispatch, auth]);

  return <div />;
  // Button to test Disconnect socket
  // (
  //   <button
  //     style={{ position: 'fixed', top: 100, left: 100 }}
  //     onClick={() => {
  //       socket.current.close();
  //       // accessTokenRef.current = ERR_TOKEN;
  //     }}
  //   >
  //     Disconnect
  //   </button>
  // );
};

export default ConnectSocket;
