import React, { useState, useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { styleOverflowDefault } from 'utils';
import './HBModal.scss';

const getTransitionClass = (s: string) => {
  return s === 'entering' ? 'd-block' : s === 'entered' ? 'show d-block' : s === 'exiting' ? 'd-block' : '';
};

interface Props {
  className?: string;
  contentClassname?: string;
  innerRef?: any;
  show: boolean;
  onClose: any;
  centered?: boolean;
  size?: '' | 'sm' | 'lg' | 'xl';
  backdrop?: boolean;
  closeOnBackdrop?: boolean;
  closeBtn?: boolean;
  style?: any;
  backdropOpacity?: number;
}

const HBModal: React.FC<Props> = (props) => {
  const {
    className = '',
    contentClassname = '',
    innerRef,
    show,
    centered,
    size,
    backdrop,
    closeOnBackdrop,
    onClose,
    closeBtn,
    style,
    backdropOpacity,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  // const [modalTrigger, setModalTrigger] = useState(false);
  const modalClick = (e: any) => e.target.dataset.modal && closeOnBackdrop && close();

  useEffect(() => {
    setIsOpen(show);

    if (show) {
      // document.body.style.overflow = 'hidden';
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    } else {
      // document.body.style.overflow = 'unset';
      document.getElementsByTagName('html')[0].style.overflowY = styleOverflowDefault;
    }
  }, [show]);

  const close = () => {
    onClose && onClose();
    setIsOpen(false);
  };

  const onEntered = () => {
    // setModalTrigger(document.querySelector(':focus'));
    // nodeRef.current.focus();
    // onOpened && onOpened();
  };

  const onExited = () => {
    // modalTrigger && modalTrigger.focus();
    // onClosed && onClosed();
  };

  const dialogClasses = `modal-dialog modal-dialog-scrollable${centered ? ' modal-dialog-centered' : ''}${
    size ? ` modal-${size}` : ''
  }`;

  const backdropClasses = 'modal-backdrop fade show';

  const nodeRef = useRef(null);
  return (
    <div onClick={modalClick}>
      <Transition in={Boolean(isOpen)} onEntered={onEntered} onExited={onExited} timeout={150} nodeRef={nodeRef}>
        {(status) => {
          let transitionClass = getTransitionClass(status);
          const classes = `modal overflow-auto fade ${transitionClass}`;
          return (
            <div tabIndex={-1} role="dialog" className={`${classes} ${className}`} data-modal={true} ref={nodeRef}>
              <div className={dialogClasses} role="document">
                <div
                  className={`hb-modal-content ${closeBtn && 'hasClose'} ${contentClassname}`}
                  ref={innerRef}
                  style={style}
                >
                  {closeBtn && <i className="hb-icon-cross hb-modal-close" onClick={onClose} />}
                  {props.children}
                </div>
              </div>
            </div>
          );
        }}
      </Transition>
      {backdrop && isOpen && <div className={backdropClasses} style={{ opacity: backdropOpacity }}></div>}
    </div>
  );
};

HBModal.defaultProps = {
  backdrop: true,
  closeOnBackdrop: true,
};

export default HBModal;
