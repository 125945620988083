import React from 'react';
import { InputApiType, OutputApiType } from 'store/callApi/actions';

export const MAX_THUMBNAIL_SIZE = 52428800;

export type AdminConfirmPopupType = {
  isVisible?: boolean;
  title?: string;
  content?: string;
  leftButtonText?: string;
  rightButtonText?: string;
  leftButtonOnPress?: any;
  rightButtonOnPress?: any;
};

export type AdminPermissionType = {
  create: boolean;
  update: boolean;
  delete: boolean;
  name: string;
  [key: string]: any;
};

export const AdminContext = React.createContext<{
  showPopup: (values: AdminConfirmPopupType) => void;
  hidePopup: () => void;
  permissions: AdminPermissionType[];
  hasPermission: (permission: string) => { create: boolean; update: boolean; delete: boolean };
  callApi: (payload: InputApiType, callback?: (result: OutputApiType) => void) => void;
}>({
  showPopup: () => {},
  hidePopup: () => {},
  permissions: [],
  hasPermission: () => ({ create: false, update: false, delete: false }),
  callApi: () => {},
});

export const USER_TYPES = {
  SUPER_ADMIN: 'superadmin',
  ADMIN: 'admin',
  NURSE: 'nurse',
  USER: 'user',
  ASSISTANT: 'assistant',
};

const { ADMIN, NURSE, USER, ASSISTANT } = USER_TYPES;

export const mappingUserType: any = {
  [USER]: 'basic-member',
  [ADMIN]: 'admin',
  [NURSE]: 'nurse',
  [ASSISTANT]: 'assistants',
};

export const notIsAdminGroups = [NURSE, USER, ASSISTANT];

export const ADMIN_PERMISSIONS = {
  MANAGE_ADMINS: 'SuperadminManageAdmin',
  MANAGE_NURSES: 'AdminManageNurse',
  MANAGE_USERS: 'AdminManagebBasicUser',
  MANAGE_ASSISTANTS: 'AdminManageAssistant',
  MANAGE_NEWSFEED_CATEGORIES: 'AdminManageNewsfeedCategory',
  MANAGE_NEWSFEED_ARTICLES: 'AdminManageNewsfeedArticle',
  MANAGE_MOBILITY_VIDEOS: 'AdminManageVideo',
  MANAGE_MOBILITY_CATEGORIES: 'AdminManageMobilityCategory',
  MANAGE_MOBILITY_TRAINERS: 'AdminManageTrainers',
  MANAGE_CONSULTING_CATEGORIES: 'AdminManageConsultatingCategory',
  MANAGE_CONSULTING_TOPICS: 'AdminManageConsultatingTopics',
  MANAGE_INSURANCES: 'AdminManagebBasicUser',
  MANAGE_DIPA: 'AdminManagebBasicUser',
  MANAGE_GROUPS: 'SuperAdminManageGroup',
  VIEW_GROUPS: 'AdminViewGroup',
  MANAGE_PERMISSIONS: 'SuperAdminManagePermission',
  VIEW_PERMISSIONS: 'AdminViewPermission',
  MANAGE_NOTIFICATIONS: 'SuperAdminManageNotification',
  MANAGE_SETTINGS: 'Admin settings',
  MANAGE_PROFILE: 'Admin profile',
  VIEW_STATISTICS: 'SuperadminStats',
};

const {
  MANAGE_ADMINS,
  MANAGE_NURSES,
  MANAGE_USERS,
  MANAGE_ASSISTANTS,
  MANAGE_NEWSFEED_CATEGORIES,
  MANAGE_MOBILITY_VIDEOS,
  MANAGE_MOBILITY_CATEGORIES,
  MANAGE_MOBILITY_TRAINERS,
  MANAGE_CONSULTING_CATEGORIES,
  MANAGE_CONSULTING_TOPICS,
  VIEW_GROUPS,
  VIEW_PERMISSIONS,
  MANAGE_NOTIFICATIONS,
  MANAGE_SETTINGS,
  MANAGE_PROFILE,
  VIEW_STATISTICS,
} = ADMIN_PERMISSIONS;

export const ADMIN_ROUTES = {
  ADMIN_MANAGEMENT: '/internal/admin/users',
  NURSE_MANAGEMENT: '/internal/admin/users?type=nurse',
  USER_MANAGEMENT: '/internal/admin/users?type=user',
  ASSISTANT_MANAGEMENT: '/internal/admin/users?type=assistant',
  NEWSFEED_CATEGORY_MANAGEMENT: '/internal/admin/newsfeed',
  MOBILITY_VIDEO_MANAGEMENT: '/internal/admin/mobility/videos',
  MOBILITY_CATEGORY_MANAGEMENT: '/internal/admin/mobility/categories',
  MOBILITY_TRAINER_MANAGEMENT: '/internal/admin/mobility/trainers',
  COMMUNICATION_MANAGEMENT: '/internal/admin/communication',
  CONSULTING_CATEGORY_MANAGEMENT: '/internal/admin/consulting/categories',
  CONSULTING_TOPIC_MANAGEMENT: '/internal/admin/consulting/topics',
  GROUP_MANAGEMENT: '/internal/admin/groups',
  PERMISSION_MANAGEMENT: '/internal/admin/permissions',
  NOTIFICATION_MANAGEMENT: '/internal/admin/notifications',
  SETTING_MANAGEMENT: '/internal/admin/settings',
  PROFILE: '/internal/admin/profile',
  INSURANCES_MANAGEMENT: '/internal/admin/insurances',
  DIPA_MANAGEMENT: '/internal/admin/dipa',
  STATISTICS: '/internal/admin/statistics',
};

const {
  ADMIN_MANAGEMENT,
  NURSE_MANAGEMENT,
  USER_MANAGEMENT,
  ASSISTANT_MANAGEMENT,
  NEWSFEED_CATEGORY_MANAGEMENT,
  MOBILITY_VIDEO_MANAGEMENT,
  MOBILITY_CATEGORY_MANAGEMENT,
  MOBILITY_TRAINER_MANAGEMENT,
  CONSULTING_CATEGORY_MANAGEMENT,
  CONSULTING_TOPIC_MANAGEMENT,
  GROUP_MANAGEMENT,
  PERMISSION_MANAGEMENT,
  NOTIFICATION_MANAGEMENT,
  SETTING_MANAGEMENT,
  PROFILE,
  STATISTICS,
  INSURANCES_MANAGEMENT,
  DIPA_MANAGEMENT,
} = ADMIN_ROUTES;

// Check first permission admin has, and redirect to corresponding location
export const priorityRoutes = [
  { permission: MANAGE_ADMINS, path: ADMIN_MANAGEMENT },
  { permission: MANAGE_NURSES, path: NURSE_MANAGEMENT },
  { permission: MANAGE_USERS, path: USER_MANAGEMENT },
  { permission: MANAGE_USERS, path: DIPA_MANAGEMENT },
  { permission: MANAGE_USERS, path: INSURANCES_MANAGEMENT },
  { permission: MANAGE_ASSISTANTS, path: ASSISTANT_MANAGEMENT },
  { permission: MANAGE_NEWSFEED_CATEGORIES, path: NEWSFEED_CATEGORY_MANAGEMENT },
  { permission: MANAGE_MOBILITY_VIDEOS, path: MOBILITY_VIDEO_MANAGEMENT },
  { permission: MANAGE_MOBILITY_CATEGORIES, path: MOBILITY_CATEGORY_MANAGEMENT },
  { permission: MANAGE_MOBILITY_TRAINERS, path: MOBILITY_TRAINER_MANAGEMENT },
  { permission: MANAGE_CONSULTING_CATEGORIES, path: CONSULTING_CATEGORY_MANAGEMENT },
  { permission: MANAGE_CONSULTING_TOPICS, path: CONSULTING_TOPIC_MANAGEMENT },
  { permission: VIEW_GROUPS, path: GROUP_MANAGEMENT },
  { permission: VIEW_PERMISSIONS, path: PERMISSION_MANAGEMENT },
  { permission: MANAGE_NOTIFICATIONS, path: NOTIFICATION_MANAGEMENT },
  { permission: MANAGE_SETTINGS, path: SETTING_MANAGEMENT },
  { permission: MANAGE_PROFILE, path: PROFILE },
  { permission: VIEW_STATISTICS, path: STATISTICS },
];
