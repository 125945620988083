// COMMON
export const LANG_EN = 'en';
export const LANG_DE = 'de';
export const AUTH_TYPES = {
  USER: 'user',
  NURSE: 'nurse',
  ASSISTANT: 'assistant',
};
export const GENDER = {
  FEMALE: 'Weiblich',
  MALE: 'Männlich',
  OTHER: 'Divers',
};
export const GENDER_VALUES = {
  [GENDER.FEMALE]: 1,
  [GENDER.MALE]: 0,
  [GENDER.OTHER]: 2,
};
export const GENDER_OPTIONS = [...Object.values(GENDER)].map((gender) => ({
  label: gender,
  value: GENDER_VALUES[gender],
}));
export const DEFAULT_PAGE_SIZE = 12;

// BOOK AN APPOINTMENT
export const APPOINTMENT_NURSE_STEPS = {
  TOPICS: 0, // because step topic not show in progress bar
  EMERGENCY_AID: 1,
  ADVICE_FOR_WHOM: 2,
  SURVEY: 3,
  SCHEDULE: 4,
  ALMOST_DONE: 5,
  COST: 6,
  OVERVIEW: 7,
  FINISH: 8,
};
export const APPOINTMENT_ASSISTANT_STEPS = {
  FREQUENCY_TYPE: 1,
  FREQUENCY: 2,
  ALMOST_DONE: 3,
  COST: 4,
  OVERVIEW: 5,
  FINISH: 6,
};
export const QUESTION_TYPES = {
  TEXTBOX: 1,
  RADIO: 2,
  CHECKBOX: 3,
  YES_NO: 4,
};
export const QUESTION_YES_NO_VALUES = {
  JA: 'Ja',
  NEIN: 'Nein',
};
export const QUESTION_YES_NO_ICONS: { [key: string]: string } = {
  [QUESTION_YES_NO_VALUES.NEIN]: 'cross',
  [QUESTION_YES_NO_VALUES.JA]: 'check',
};
export const SCHEDULE_OPTIONS = {
  TODAY: 'Heute',
  TOMORROW: 'Morgen',
  LATER: 'Später',
  OTHER: 'Andere',
};
export const APPOINTMENT_TYPES = {
  NURSE: 'nurse',
  ASSISTANT: 'assistant',
};
export const APPOINTMENT_TYPE_VALUES = {
  [APPOINTMENT_TYPES.NURSE]: 1,
  [APPOINTMENT_TYPES.ASSISTANT]: 2,
};
export const APPOINTMENT_REQUEST_TYPES = {
  REQUESTED: 'requested',
  ACCEPTED: 'accepted',
  FINISHED: 'finished',
  CANCELLED: 'cancelled',
};
export const APPOINTMENT_REQUEST_TYPE_VALUES = {
  [APPOINTMENT_REQUEST_TYPES.REQUESTED]: 0,
  [APPOINTMENT_REQUEST_TYPES.ACCEPTED]: 1,
  [APPOINTMENT_REQUEST_TYPES.FINISHED]: 2,
  [APPOINTMENT_REQUEST_TYPES.CANCELLED]: 3,
};
export const APPOINTMENT_FREQUENCY = {
  RECURRING: 'Wiederkehrend',
  ONE_TIME: '',
};
export const APPOINTMENT_FREQUENCY_OPTIONS = [
  {
    label: 'Einmalige Buchung',
    value: '',
  },
];
export const MAX_SCHEDULE_TIME = '17:00';
export const MAX_SCHEDULE_TIME_START = '16:45';
export const DEFAULT_SCHEDULE_TIME_START = '09:00';
export const MIN_TIME_SET_SCHEDULE_TODAY = '05:00';
export const MAX_TIME_SET_SCHEDULE_TODAY = '12:45';
export const ADDITIONAL_HOURS_TIME_START = 4;
export const VALID_TIME_START_SUBMIT = 3;
export const DAYS_OFF = [0, 6]; // AS SATURDAY AND SUNDAY
export const ADDITIONAL_BOOKING_DAYS = 28;
export const DURATION_OPTIONS = [15, 30, 45, 60].map((duration) => ({
  label: `${duration} Minuten`,
  value: duration === 60 ? '01:00' : `00:${duration}`,
}));
export const FORMAT_DATETIME_CONVERT = 'YYYY-MM-DDTHH:mm:ss';

// VIEW APPOINTMENTS
export const DEFAULT_VIEW_APPOINTMENTS_FILTER = {
  selectedDate: '',
  timeStart: DEFAULT_SCHEDULE_TIME_START,
  timeEnd: MAX_SCHEDULE_TIME,
};
export const VIEW_APPOINTMENTS_MODES = { VIEW: 1, FILTER: 2, EDIT: 3 };
export const SUFFIX_API_APPOINTMNET_DETAIL = {
  [AUTH_TYPES.USER]: 'users',
  [AUTH_TYPES.NURSE]: 'nurses',
  [AUTH_TYPES.ASSISTANT]: 'assistants',
};

/**
 * VALIDATE
 */
export const MAX_LENGTH_FIRST_NAME = 50;
export const MAX_LENGTH_LAST_NAME = 50;
export const MAX_LENGTH_EMAIL = 50;
export const MIN_LENGTH_EMAIL = 3;
export const MAX_LENGTH_PHONE = 20;
export const MAX_LENGTH_STREET = 50;
export const MAX_LENGTH_NO = 10;
export const MAX_LENGTH_POSTAL_CODE = 5;
export const MAX_LENGTH_PLACE = 50;
export const MAX_LENGTH_HEALTH_INSURANCE = 20;
export const MAX_LENGTH_INSURED_PERSON_NUM = 20;
export const MAX_QA_INPUT_LENGTH = 200;
export const MAX_SUB_QA_INPUT_LENGTH = 100;
export const MAX_LIMIT_INT = 0x7fffffff;

/**
 * ERROR
 */
export const MSG_ERROR_CANNOT_CANCEL_APPOINTMENT = '1081';
export const MSG_ERROR_CONFLICT_SCHEDULE = '1086';
export const MSG_ERROR_INVALID_BOOK_TIME = '1144';
export const MSG_ERROR_CONFLICT_USER_BOOK_TIME = '1145';
export const MSG_ERROR_HAVE_ALREADY_REPORT = '1164';

/**
 * OTHER
 */
export const BUFFER_PAGE = 2;

export const ACTIVITY_TYPES = {
  GENERAL: 'general',
  ARTICLE_OF_WEEK: 'article_of_week',
  NEW_VIDEO: 'new_video',
  UPCOMING_APPOINTMENT: 'upcoming_appointment',
  TRAINER: 'trainer',
  NEW_REPORT: 'new_report',
  WEBHOOK_STRIPE: 'webhook_stripe',
  CUSTOM_NOTIFICATION: 'custom_notification',
};

export const POSTURE_TYPES = {
  ALL: 'all',
  STANDING: 'standing',
  SIT: 'sit',
  LIE: 'lie',
};

export const MAX_DOCUMENT_SIZE = 52428800;
export const ESIGNATURE_STATUS = {
  SENT: 'sent',
  COMPLETE: 'completed',
  DOCUSIGN_CALLBACK_COMPLETED: 'signing_complete',
  DOCUSIGN_VIEWING_COMPLETED: 'viewing_complete',
};

export const CARD_TYPES = {
  CARD: 'CARD',
  SEPA_DEBIT: 'SEPA_DEBIT',
  SOFORT: 'SOFORT',
};

export const STATUS_SUBSCRIPTION = {
  INCOMPLETE: 'INCOMPLETE',
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  PAST_DUE: 'PAST_DUE',
  UNPAID: 'UNPAID',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  TRIALING: 'TRIALING',
};

export const STATUS_PAYMENTINTENT = {
  SUCCEEDED: 'SUCCEEDED',
  REQUIRES_PAYMENT_METHOED: 'REQUIRES_PAYMENT_METHOED',
  REQUIRES_ACTION: 'REQUIRES_ACTION',
};

export const PAYMENT_STATUS = {
  PROCESSING: 'processing',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  UNPAID: 'unpaid',
};

export const WARNING_CODE = '1';
export const DEFAULT_MESSAGE_ERROR =
  'Entschuldigen Sie für die Unannehmlichkeiten. Bitte versuchen Sie es noch einmal.';

export const APPOINTMENT_INFO_CONTENTS: any = {
  2: 'Dieser Termin wurde dürchgefuhrt.',
  3: 'Dieser Termin wurde stoniert.',
};

export const NETWORK_ERROR_CODE = 0;

export const DATEPICKER_CONFIGS: any = {
  locale: 'de',
  calendarStartDay: 1,
};
