import Checkbox from '../checkbox/Checkbox';
import React, { useEffect, useRef, useState } from 'react';
import FrameLoading from '../frameLoading/FrameLoading';
import './SelectLoadmore.css';

interface Props {
  placeholder?: string;
  className?: string;
  selected: any[];
  setSelected: any;
  data: any[];
  loading: boolean;
  callbackSearch?: (search: string) => void;
  value: string;
  setValue: (param: any) => void;
}

const SelectCheckboxes: React.FC<Props> = (props) => {
  const { placeholder, className, selected, setSelected, data, loading, callbackSearch, value, setValue } = props;

  const [show, setShow] = useState(false);

  const ref = useRef<any>(null);
  const timeout = useRef<any>(0);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleChangeValue = (e: any) => {
    const v = e.target.value;

    setValue(v);
    if (timeout.current) clearTimeout(timeout.current);
    const trimmedSearchName = v.trim();
    timeout.current = setTimeout(() => {
      callbackSearch?.(trimmedSearchName);
    }, 1000);
  };

  let render;

  const selectedIds = selected.map((item) => item.id);
  const selectAll = data.every((item) => selectedIds.includes(item.id));

  const handleSelect = (item: any) => {
    setSelected((selected: any[]) => {
      if (!selectedIds.includes(item.id)) return [...selected, item];
      return selected.filter((s) => s.id !== item.id);
    });
  };

  const handleSelectAll = () => {
    setSelected((selected: any[]) => {
      if (selectAll) return [];
      let newSelected = [...selected];
      data.forEach((item) => {
        if (!selectedIds.includes(item.id)) newSelected.push(item);
      });
      return newSelected;
    });
  };

  if (loading)
    render = (
      <div className="d-flex align-items-center" style={{ height: '80%' }}>
        <FrameLoading size="sm" />
      </div>
    );
  else if (data.length === 0) render = <div className="select-suggest-no-results">No results</div>;
  else {
    render = (
      <div>
        <div className="select-suggest-checkbox-item">
          <Checkbox checked={selectAll} onChange={handleSelectAll} />
          All
        </div>
        {data.map((item: { id: string; name: string }) => (
          <div key={item.id} className="select-suggest-checkbox-item">
            <Checkbox checked={selectedIds.includes(item.id)} onChange={() => handleSelect(item)} />
            {item.name}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div ref={ref} className={`d-flex align-items-center ${className}`} style={{ position: 'relative', height: 35 }}>
      <input
        value={value}
        onChange={handleChangeValue}
        onClick={() => setShow(true)}
        className="form-control pr-4 select-loadmore"
        placeholder={placeholder || 'Select resource'}
        maxLength={50}
      />
      <i
        onClick={() => setShow(true)}
        className="fa fa-angle-down cursor-pointer"
        style={{ position: 'absolute', right: 10 }}
      />
      {show && (
        <div className="select-suggest-wrapper" style={{ maxHeight: 260 }}>
          {render}
        </div>
      )}
    </div>
  );
};

export default SelectCheckboxes;
