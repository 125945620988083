import { CSelect } from '@coreui/react';
import { isNil } from 'lodash';

import FormGroupCommon, { FormGroupCommonTypes } from './FormGroupCommon';
import { DropdownList } from '../select';
import { isMobile } from 'utilities';
import { OptionType } from 'types/common';

import './FormGroup.scss';

interface FormGroupSelectTypes extends Omit<FormGroupCommonTypes, 'onChange'> {
  options: OptionType[];
  onChange?: any;
}

const FormGroupSelect = (props: FormGroupSelectTypes & Omit<CSelect, 'onChange'>) => {
  const { className = '', options = [], onChange = () => {}, ...rest } = props;

  /**
   * Handle change value
   *
   * @param event DOM Event or Value
   */
  const handleChangeValue = (event: any) => {
    let value = event;
    if (typeof event === 'object' && !isNil(event)) {
      value = event?.target?.value;
    }

    onChange(value);
  };

  return (
    <FormGroupCommon className={`FormGroupSelect-container ${className}`} {...rest}>
      {isMobile() ? (
        <CSelect onChange={handleChangeValue} {...rest}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </CSelect>
      ) : (
        <DropdownList options={options} onChange={handleChangeValue} {...rest} />
      )}
    </FormGroupCommon>
  );
};

export default FormGroupSelect;
