import { includes, trim } from 'lodash';
import moment from 'moment';
import {
  QUESTION_TYPES,
  MAX_QA_INPUT_LENGTH,
  MAX_SUB_QA_INPUT_LENGTH,
  VALID_TIME_START_SUBMIT,
  SCHEDULE_OPTIONS,
  DAYS_OFF,
} from 'configs/constants';

const { TODAY, TOMORROW, LATER } = SCHEDULE_OPTIONS;

/**
 * Validate book an appointment survey's answers
 *
 * @param questions Questions get from database
 * @param answers User input answer
 *
 * @return Boolean
 */
export const validateBookAppointmentAnswers = (questions: any = [], answers: any = {}) => {
  return questions.every((questionItem: any) => {
    const { id: questionId, required, question_type, answers: qAnswers = [] } = questionItem;
    const answerValues = qAnswers.reduce((acc: any, answerItem: any) => [...acc, answerItem.answer_title], []);

    // get current question's answer
    const { answer = '', parentQuestionId } = answers[questionId] || {};

    // validate required
    const trimAnswer = typeof answer === 'string' ? trim(answer) : answer;
    if (required && !trimAnswer) return false;

    // validate value
    switch (question_type) {
      case QUESTION_TYPES.YES_NO:
      case QUESTION_TYPES.RADIO: {
        if (!includes(answerValues, answer)) {
          return false;
        }
        break;
      }
      case QUESTION_TYPES.TEXTBOX: {
        if (answer.length > MAX_QA_INPUT_LENGTH) return false;
        if (parentQuestionId && answer.length > MAX_SUB_QA_INPUT_LENGTH) return false;
      }
    }

    // validate sub questions
    const currentAnswerData = qAnswers.find((qAnswer: any) => qAnswer.answer_title === answer);
    if (currentAnswerData) {
      const { sub_questions = [] } = currentAnswerData;

      const isValidSubQA = validateBookAppointmentAnswers(sub_questions, answers);
      if (!isValidSubQA) return false;
    }

    return true;
  });
};

/**
 * Check validate schedule date is days off or working day
 *
 * @param scheduleData Schedule data
 */
export const isValidBookAppointmentDate = (scheduleData: any) => {
  const { selectedOption, specificDate } = scheduleData || {};
  const bookDateValues = {
    [TODAY]: moment(),
    [TOMORROW]: moment().add(1, 'd'),
    [LATER]: specificDate,
  };
  const checkingDate = bookDateValues[selectedOption] || specificDate;

  return !includes(DAYS_OFF, moment(checkingDate).day());
};

/**
 * Check valid book an appoint schedule
 *
 * @param scheduleData Schedule data
 *
 * @return boolean
 */
export const isValidBookAppointmentSchedule = (scheduleData: any) => {
  const { selectedOption, timeStart, specificDate } = scheduleData || {};
  const isToday = selectedOption === TODAY;
  // Check working day
  const isWorkingDay = isValidBookAppointmentDate(scheduleData);
  if (!isWorkingDay) {
    return false;
  }

  const currentDate = moment().format('YYYY-MM-DD');
  const duration = moment.duration(moment(`${currentDate} ${timeStart}`).diff(moment()));
  const hours = duration.asHours();
  const specificDateVal = moment(specificDate).format('YYYY-MM-DD');

  // check valid specificDate
  const isValidDate =
    selectedOption === TOMORROW || ((!selectedOption || selectedOption === LATER) && currentDate < specificDateVal);

  return isToday ? hours >= VALID_TIME_START_SUBMIT : isValidDate;
};
