import { store } from '../store';
import { AUTH_TYPES } from 'configs/constants';
const { USER, NURSE, ASSISTANT } = AUTH_TYPES;

/**
 * Get group name of user
 *
 * @return string
 */
const getAuthGroupName = () => {
  const reducerState = store.getState();
  const { auth } = reducerState;
  const { group_name = '' } = auth || {};

  return group_name;
};

/**
 * Check auth is user
 *
 * @return boolean
 */
export const isRoleUser = () => {
  return getAuthGroupName() === USER;
};

/**
 * Check auth is nurse
 *
 * @return boolean
 */
export const isRoleNurse = () => {
  return getAuthGroupName() === NURSE;
};

/**
 * Check auth is assistant
 *
 * @return boolean
 */
export const isRoleAssistant = () => {
  return getAuthGroupName() === ASSISTANT;
};
