import React from 'react';
import { CSpinner } from '@coreui/react';

import HBModal from './HBModal';
import HBButtonFull from '../hbButton/HBButtonFull';
import HBInput from '../hbInput/HBInput';
import { DEFAULT_MESSAGE_ERROR } from 'configs/constants';

interface Props {
  className?: string;
  show?: boolean;
  handleClose?: () => void;
  title?: string;
  content?: any;
  up?: string;
  down?: string;
  upCallback?: () => void;
  downCallback?: () => void;
  isLoading?: boolean;
  input?: {
    onChange?: any;
    value?: any;
    name?: any;
    type?: any;
    placeholder?: any;
    autoComplete?: any;
  } | null;
  label?: string | null;
  payment?: boolean;
  hideCloseBtn?: boolean;
  backdropOpacity?: number;
  subContent?: string;
  isDisableDownButton?: boolean;
  closeOnBackdrop?: boolean;
  onClose?: () => void;
}

const HBModalConfirm: React.FC<Props> = (props) => {
  const {
    className = '',
    show,
    handleClose,
    title,
    content,
    up,
    down,
    upCallback,
    downCallback,
    isLoading,
    input,
    label,
    payment,
    hideCloseBtn,
    backdropOpacity,
    subContent,
    isDisableDownButton,
    ...rest
  } = props;

  return (
    <HBModal
      className={className}
      backdropOpacity={backdropOpacity}
      show={show || false}
      onClose={handleClose}
      closeOnBackdrop={false}
      centered
      closeBtn={!hideCloseBtn}
      {...rest}
    >
      <div className={payment ? 'hb-payment-modal-confirm-wrapper' : 'hb-modal-confirm-wrapper'}>
        <div className={payment ? 'hb-modal-payment-title' : 'hb-modal-title'}>{title}</div>
        <div className={payment ? 'hb-modal-payment-body' : 'hb-modal-body'}>
          {!content || content === `""` ? DEFAULT_MESSAGE_ERROR : content}
        </div>
        {input && (
          <div>
            <div className="hb-modal-confirm-label">{label}</div>
            <HBInput
              onChange={input?.onChange}
              value={input.value}
              type={input.type}
              placeholder={input.placeholder}
              autoComplete={input.autoComplete}
              name={input.name}
              petrol
            />
          </div>
        )}
        {up && (
          <div style={{ height: 52, width: '100%' }}>
            <HBButtonFull disabled={isLoading} onClick={upCallback}>
              {isLoading ? <CSpinner color="light" size="sm" /> : up}
            </HBButtonFull>
          </div>
        )}
        {down && (
          <div style={{ height: 52, width: '100%', marginTop: 8 }}>
            <HBButtonFull disabled={isLoading || isDisableDownButton} onClick={downCallback} outline>
              {down}
            </HBButtonFull>
          </div>
        )}
        {subContent && <div className="hb-modal-confirm-subcontent">{subContent}</div>}
      </div>
    </HBModal>
  );
};

export default HBModalConfirm;
