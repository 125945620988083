import moment from 'moment';
import { trim } from 'lodash';
import { SCHEDULE_OPTIONS, APPOINTMENT_TYPES, APPOINTMENT_TYPE_VALUES, APPOINTMENT_FREQUENCY } from 'configs/constants';
import { ScheduleData } from 'types/appointments';

const { NURSE } = APPOINTMENT_TYPES;
const APPOINTMENT_TYPE_NURSE_VALUE = APPOINTMENT_TYPE_VALUES[NURSE];

/**
 * Convert appointment schedule time
 *
 * @param scheduleData Schedule data
 *
 * @return object
 */
export const convertAppointmentScheduleTime = (
  scheduleData: ScheduleData,
  appointmentType: number = APPOINTMENT_TYPE_NURSE_VALUE,
) => {
  const { selectedOption, specificDate, timeStart, timeEnd, duration } = scheduleData;

  // convert schedule time
  let mmAppointmentDate = moment();
  if (selectedOption !== SCHEDULE_OPTIONS.TODAY) {
    mmAppointmentDate = selectedOption === SCHEDULE_OPTIONS.LATER ? moment(specificDate) : moment().add(1, 'd');
  }
  const appointmentDate = mmAppointmentDate.format('YYYY-MM-DD');
  const appointment_time_begin = moment(`${appointmentDate} ${timeStart}`).utc().unix();
  const appointment_time_end = moment(`${appointmentDate} ${timeEnd}`).utc().unix();

  // get time for book nurse
  if (appointmentType === APPOINTMENT_TYPE_NURSE_VALUE) {
    return { appointment_time_begin, appointment_time_end };
  }

  return { appointment_time_begin, time_block_id: duration };
};

/**
 * Get book an appointment request params
 *
 * @param appointmentData Book an appointment data
 * @param appointmentType For nurse or assistant
 *
 * @return Object
 */
export const getBookAppointmentRequestParams = (
  appointmentData: any = {},
  appointmentType = APPOINTMENT_TYPE_NURSE_VALUE,
) => {
  const {
    selectedTopic,
    adviceRelatives = [],
    scheduleData = {},
    userInfo = {},
    surveyAnswers = {},
    frequencyData,
  } = appointmentData || {};
  const { phone_number = '', street, gender, postal_code, no, place } = userInfo;
  const { frequencyType, frequencyOption = '' } = frequencyData || {};

  // convert question's answer data
  const questions = [...Object.values(surveyAnswers)].reduce((acc: any[], answerItem: any) => {
    const { question_id, answer, question_title, short_title, parentQuestionId } = answerItem || {};
    const trimAnswer = typeof answer === 'string' ? trim(answer) : answer;
    const answerData = { question_id, answer: trimAnswer, question_title, short_title };

    // if question is not sub question
    if (!parentQuestionId) {
      return [...acc, { ...answerData, sub_questions: [] }];
    }

    // add sub question into parent question
    acc = acc.map((formatAnswerItem) => {
      const { question_id: formatQuestionId, sub_questions: formatSubQuestions } = formatAnswerItem || {};
      // if finded parent question
      if (formatQuestionId === parentQuestionId) {
        return {
          ...formatAnswerItem,
          sub_questions: [...formatSubQuestions, answerData],
        };
      }

      return formatAnswerItem;
    });
    return acc;
  }, []);

  // convert schedule time
  const scheduleTime = convertAppointmentScheduleTime(scheduleData, appointmentType);
  const commonRequestParams = {
    ...scheduleTime,
    appointment_type: appointmentType,
    customer_gender: gender,
    phone_number,
    street,
    postcode: postal_code,
    no,
    place,
  };

  // if book a nurse
  if (appointmentType === APPOINTMENT_TYPE_NURSE_VALUE) {
    return {
      ...commonRequestParams,
      result_survey: { questions },
      selected_treatments_id: selectedTopic,
      relatives: adviceRelatives,
    };
  }

  return {
    ...commonRequestParams,
    recurring_calendar_id: frequencyType === APPOINTMENT_FREQUENCY.ONE_TIME ? '' : frequencyOption,
  };
};
