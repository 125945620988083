import { PAID, UNPAID } from './actions';

const initialState = { show: false, message: "", payment: false };

export const paymentReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case PAID:
            return { ...initialState };

        case UNPAID:
            return { ...state, show: true, message: action.payload.message, payment: action.payload.payment };

        default:
            return state;
    }
};