import React from 'react';

interface Props {
  petrol?: boolean;
  onChange: any;
  value: any;
  type?: string;
  placeholder: string;
  autoComplete?: string;
  name: string;
  onBlur?: any;
  className?: string;
  autoFocus?: boolean;
  maxLength?: number;
  require?: boolean;
  onKeyDown?: any;
}
const HBInput: React.FC<Props> = (props) => {
  const {
    petrol,
    onChange,
    value,
    name,
    type,
    placeholder,
    autoComplete,
    onBlur,
    className,
    autoFocus,
    maxLength,
    require,
    onKeyDown,
  } = props;

  return (
    <input
      type={type}
      className={`hb-input ${className}`}
      value={value}
      style={{ background: petrol ? 'var(--petrol-5)' : '#fff' }}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete={autoComplete}
      name={name}
      onBlur={onBlur}
      autoFocus={autoFocus}
      maxLength={maxLength}
      required={require}
      onKeyDown={onKeyDown}
    />
  );
};
export default HBInput;
